import styled, { css } from 'styled-components/macro';
import {
  BaseTextBlock,
  BaseButton,
  flexCenter,
  BaseGameScreenWrapper
} from '../../styles/shared-styles';
import { colors, screens } from '../../styles/theme';

export const Wrapper = styled(BaseGameScreenWrapper)`
  ${flexCenter}

  .gameover-logo {
    max-width: 190px;
  }

  @media (min-width: ${screens.sm}) {
    .gameover-logo {
      max-width: 334px;
    }
  }
`;

const sharedFontSize = css`
  font-size: 1.25rem;

  @media (min-width: ${screens.sm}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${screens.xl}) {
    font-size: 1.75rem;
  }
`;

export const TextBlock = styled(BaseTextBlock)`
  ${sharedFontSize}
  text-align: center;
  margin-top: 1rem;

  &.is-highlighted {
    border: 2px solid #fff;
    padding: 0.5rem 0.75rem;

    @media (min-width: ${screens.lg}) {
      border-width: 3px;
      padding: 0.75rem 1rem;
    }
  }
`;

export const Button = styled(BaseButton)`
  ${sharedFontSize}
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: ${colors.red};

  @media (min-width: ${screens.md}) {
    margin-top: 1.5rem;
  }
`;
