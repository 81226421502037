import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GameMenu from '../GameMenu/GameMenu';
import GameManager from '../GameManager/GameManager';
import SpritesManager from '../SpritesManager/SpritesManager';
// import HostGame from '../HostGame/HostGame';
// import RemoteGame from '../RemoteGame/RemoteGame';
// import SpectateGame from '../SpectateGame/SpectateGame';
// import HighscoreScreen from '../HighscoreScreen/HighscoreScreen';

function App() {
  return (
    <SpritesManager>
      <Switch>
        <Route path="/" exact component={GameMenu} />
        <Route path="/localgame/:playerCount" component={GameManager} />
        {/* these are deactivated because the server is offline */}
        {/* <Route path="/host/:gameId?" component={HostGame} /> */}
        {/* <Route path="/convention/:gameId?" render={props => <HostGame conventionMode={true} {...props} />} /> */}
        {/* <Route path="/spectate/:gameId" component={SpectateGame} /> */}
        {/* <Route path="/scores" component={HighscoreScreen} /> */}
        {/* <Route path="/:gameId/:playerId?" component={RemoteGame} /> */}
      </Switch>
    </SpritesManager>
  );
}

export default App;
