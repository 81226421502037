import React from 'react';
import * as S from './styles';

function LivesCount({ count, lifeClassName }) {
  if (count < 1) {
    return null;
  }
  return (
    <S.LivesRow>
      {[...Array(count).keys()].map((i) => (
        <S.Life className={lifeClassName} key={i} />
      ))}
    </S.LivesRow>
  );
}

export default LivesCount;
