import React, { useState } from 'react';
import { useInterval } from '../../hooks/useInterval';
import * as S from './styles';

function PauseScreen() {
  const [count, setCount] = useState(5);

  useInterval(() => {
    let nextCount = count - 1 > 0 ? count - 1 : 0;
    setCount(nextCount);
  }, 1000);

  return (
    <S.PauseWrapper>
      Looking good so far.
      <br /> Get ready for the MOTHERSHIP!
      <S.Countdown className={count > 3 ? 'hidden' : undefined}>{count}</S.Countdown>
    </S.PauseWrapper>
  );
}

export default PauseScreen;
