import React from 'react';

function GameView({ screen, innerRef }) {
  return (
    <canvas
      style={{
        width: screen.width * screen.scaleRatio,
        height: screen.height * screen.scaleRatio
      }}
      ref={innerRef}
      width={screen.width * screen.pixelRatio}
      height={screen.height * screen.pixelRatio}
    />
  );
}

function hasEqualProps(prevProps, nextProps) {
  if (prevProps.screen !== nextProps.screen) {
    return false;
  }

  return true;
}

export default React.memo(GameView, hasEqualProps);
