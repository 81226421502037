const loadSpritesFromSpriteSheet = (doc) => {
  const symbols = doc.querySelectorAll('*[id]');
  const svgNode = doc.querySelector('svg');
  const width = svgNode.getAttribute("width") || '100px'
  const height = svgNode.getAttribute("height") || '100px'

  return [...symbols].map((symbol) => {
    // const fakeSvgImage = doc.documentElement.cloneNode();
    // fakeSvgImage.append(symbol);

    // const useNode = doc.createElement('use');
    // useNode.setAttribute('xlink:href', `#${symbol.id}`);
    // fakeSvgImage.append(useNode);

    let markup = new XMLSerializer().serializeToString(symbol);
    // Width and height should be set explicitly even though they won't be used
    // This is required for Firefox, otherwise img.onload fires too early
    markup = markup.replace('<symbol', `<svg width="${width}" height="${height}"`);
    markup = markup.replace('symbol>', 'svg>');

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = (e) => resolve(img);
      img.onerror = (e) => {
        console.log('Image load error', e);
        reject(null);
      };
      img.src = 'data:image/svg+xml,' + encodeURIComponent(markup);
    });
  });
};

export const loadSpriteSheet = (url) => {
  return fetch(url)
    .then((r) => r.text())
    .then((markup) => {
      const doc = new DOMParser().parseFromString(markup, 'image/svg+xml');
      return Promise.all(loadSpritesFromSpriteSheet(doc));
    });
};

export const loadSprite = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (e) => resolve(img);
    img.onerror = (e) => {
      console.log('Image load error', e);
      reject(null);
    };
    img.src = src;
  });
};