import styled from 'styled-components/macro';
import LifeIcon from '../../assets/icons/ship-life.png';
import LifeSmallIcon from '../../assets/icons/ship-life-small.png';

export const Life = styled('div')`
  background-image: url(${LifeIcon});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 36px;
  height: 25px;

  &.is-small {
    background-image: url(${LifeSmallIcon});
    width: 16px;
    height: 11px;
  }
`;

export const LivesRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  div ~ div {
    margin-left: 5px;
  }
`;
