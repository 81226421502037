const objectSizes = {
  ship: {
    width: 30,
    height: 40,
  },
  flame: {
    width: 6,
    height: 14,
    gutter: 3,
  },
  bullets: {
    default: {
      width: 6,
      height: 10,
    },
    fast: {
      width: 8,
      height: 10,
    },
    ship: {
      width: 3.5,
      height: 9,
    },
  },
  invaders: {
    default: {
      width: 25,
      height: 25,
    },
    guard: {
      width: 40,
      height: 40,
    },
    boss: {
      width: 120,
      height: 60,
    },
  },
};

export default objectSizes;
