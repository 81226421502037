export const shipControlSchemes = {
  ANY: 0,
  ARROWS: 1,
  WASD: 2,
  REMOTE: 3
};

export const getShipActions = (keys, buttons, controlScheme) => {
  let actions = {
    left: false,
    right: false,
    fire: false
  };

  if (shipControlSchemes.ANY === controlScheme) {
    if (keys.right || keys.d) {
      actions.right = true;
    } else if (keys.left || keys.a) {
      actions.left = true;
    }
    if (keys.space || keys.shift) {
      actions.fire = true;
    }
  } else {
    const arrowsControls = shipControlSchemes.ARROWS === controlScheme;
    const wasdControls = shipControlSchemes.WASD === controlScheme;

    if ((keys.right && arrowsControls) || (keys.d && wasdControls)) {
      actions.right = true;
    } else if ((keys.left && arrowsControls) || (keys.a && wasdControls)) {
      actions.left = true;
    }
  
    if ((keys.space && arrowsControls) || (keys.shift && wasdControls)) {
      actions.fire = true;
    }
  }

  if (buttons.left) {
    if (!actions.right) {
      actions.left = true;
    }
  } else if (buttons.right) {
    if (!actions.left) {
      actions.right = true;
    }
  }

  if (buttons.fire) {
    actions.fire = true;
  }

  return actions;
};
