import styled from 'styled-components/macro';
import { BaseButton, BaseGameScreenWrapper } from '../../styles/shared-styles';
import { screens, colors } from '../../styles/theme';

export const StartScreenWrapper = styled(BaseGameScreenWrapper)`
  display: flex;
  position: relative;
  font-weight: 900;
`;

export const Logo = styled('div')`
  max-width: 200px;
  margin: 2rem auto 1rem;

  @media (min-width: ${screens.sm}) {
    max-width: 250px;
  }
`;

export const CenteredText = styled('div')`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 2.5rem;
  color: ${colors.red};
  text-transform: uppercase;
  letter-spacing: 0.25px;
  font-size: 1.25rem;
  text-align: center;
  
  @media (min-width: ${screens.sm}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${screens.xl}) {
    font-size: 2.25rem;
  }
`;

export const StartButton = styled(BaseButton)`
  color: ${colors.red};
  text-transform: uppercase;

  span {
    padding: 0 0.5rem;
  }

  svg {
    vertical-align: middle;
    margin-top: -0.2em;
    display: inline-block;
  }

  @media (min-width: ${screens.sm}) {
    svg {
      width: 27px;
      height: 27px;
    }
  }
`;

export const FooterText = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.5rem;

  @media (min-width: ${screens.sm}) {
    font-size: 0.875rem;
    padding: 1rem;
  }
`;
