import styled, { keyframes } from 'styled-components/macro';
import { flexCenter, BaseGameScreenWrapper } from '../../styles/shared-styles';
import { screens, colors } from '../../styles/theme';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const IntroWrapper = styled(BaseGameScreenWrapper)`
  ${flexCenter}
  text-align: center;
  font-size: 1.125rem;
  font-weight: bolder;
  color: ${colors.yellow};
  text-transform: uppercase;

  @media (min-width: ${screens.sm}) {
    font-size: 1.25rem;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 1.5rem;
  }

  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 0.4s ease-in 1s 1 normal forwards;
  }

  p {
    margin: 0 0 1rem;
  }

  .countdown {
    color: #fff;
    opacity: 0;
    animation-delay: 2s;
  }
`;