import React, { Fragment } from 'react';
import TouchControls from '../TouchControls/TouchControls';
import GrillIcon from '../../assets/icons/ui/grill.png';
import { ReactComponent as PowerIndicatorIcon } from '../../assets/icons/ui/power-indicator.svg';
import { ReactComponent as GameComradeIcon } from '../../assets/icons/ui/game-comrade.svg';
import ParliamentLogo from '../../assets/images/parliament-logo.png';
import * as S from './styles';

function MobileTouchGameUI({ children, onChange, pressedButtons, mode }) {
  const isTabletVertical = mode === 'tabletVertical';
  const isMobile = mode === 'mobile';

  const renderBottomUI = () => {
    if (isMobile) {
      return (
        <Fragment>
          <S.BottomUI className="ui-part">
            <S.FakeUI className="ui-optional">
              <img className="ui-grill" src={GrillIcon} alt="" />
              <GameComradeIcon className="ui-branding" />
              <img className="ui-grill" src={GrillIcon} alt="" />
              <S.HorizontalBar className="at-bottom dark-red upside-down" />
            </S.FakeUI>
            <TouchControls
              activeControls={{ fire: true, left: true, right: true }}
              onChange={onChange}
              pressedButtons={pressedButtons}
            />
            <img className="parliament-logo" src={ParliamentLogo} alt="Parliament Logo" />
            <S.HorizontalBar className="ui-part ui-optional below-bottom light-red" />
          </S.BottomUI>
          <S.HorizontalBar className="ui-part at-bottom dark-red inner" />
        </Fragment>
      );
    }

    return (
      <TouchControls
        className={isTabletVertical ? 'horizontal' : 'vertical'}
        activeControls={
          isTabletVertical
            ? { fire: true, left: true, right: true }
            : { fire: true, left: false, right: true }
        }
        onChange={onChange}
        pressedButtons={pressedButtons}
      />
    );
  };

  let wrapperClassName = 'mobileMode';
  if (!isMobile) {
    wrapperClassName = isTabletVertical ? 'tabletMode vertical' : 'tabletMode horizontal';
  }

  return (
    <S.UIWrapper className={wrapperClassName}>
      {!isMobile && !isTabletVertical && (
        <TouchControls
          className="vertical"
          activeControls={{ fire: true, left: true, right: false }}
          onChange={onChange}
          pressedButtons={pressedButtons}
        />
      )}
      <S.HorizontalBar className="ui-part at-top light-red upside-down inner" />
      <S.GameScreen className="game-screen">
        <PowerIndicatorIcon className="ui-part ui-optional power-indicator" />
        <S.HorizontalBar className="ui-part above-top dark-red upside-down" />
        {children}
        <S.HorizontalBar className="ui-part at-bottom light-red" />
      </S.GameScreen>
      {renderBottomUI()}
    </S.UIWrapper>
  );
}

export default MobileTouchGameUI;
