import CutsceneFull from '../../assets/cutscene/full.mp4';
import Scene2 from '../../assets/cutscene/scenes/scene-2.mp4';
import Scene3 from '../../assets/cutscene/scenes/scene-3.mp4';
import Scene4 from '../../assets/cutscene/scenes/scene-4.mp4';
import Scene5 from '../../assets/cutscene/scenes/scene-5.mp4';
import Scene6 from '../../assets/cutscene/scenes/scene-6.mp4';
import Scene7 from '../../assets/cutscene/scenes/scene-7.mp4';
import Scene8 from '../../assets/cutscene/scenes/scene-8.mp4';

const cutsceneData = {
  full: CutsceneFull,
  scenes: [Scene2, Scene3, Scene4, Scene5, Scene6, Scene7, Scene8],
};

export default cutsceneData;
