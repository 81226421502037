import React, { Fragment } from 'react';
import * as S from './styles';

const renderControlScheme = ({ type, isHorizontal }) => {
  const moveKeyClassName = isHorizontal ? 'color-red sans-serif' : 'color-red';
  const fireKeyClassName = 'color-red fixed-width';

  if (type === 'any' || !isHorizontal) {
    return (
      <div className="row">
        <p>
          LEFT: <span className={moveKeyClassName}>{isHorizontal ? '◀' : 'ARROW LEFT'}</span> or{' '}
          <span className={moveKeyClassName}>A</span>
        </p>
        <p>
          RIGHT: <span className={moveKeyClassName}>{isHorizontal ? '▶' : 'ARROW RIGHT'}</span> or{' '}
          <span className={moveKeyClassName}>D</span>
        </p>
        <p>
          FIRE: <span className={fireKeyClassName}>SPACE</span> or{' '}
          <span className={fireKeyClassName}>SHIFT</span>
        </p>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="row">
        <p>P1:</p>
        <p>
          LEFT: <span className={moveKeyClassName}>◀</span>
        </p>
        <p>
          RIGHT: <span className={moveKeyClassName}>▶</span>
        </p>
        <p>
          FIRE: <span className={fireKeyClassName}>SPACE</span>
        </p>
      </div>
      <div className="row">
        <p>P2:</p>
        <p>
          LEFT: <span className={moveKeyClassName}>A</span>
        </p>
        <p>
          RIGHT: <span className={moveKeyClassName}>D</span>
        </p>
        <p>
          FIRE: <span className={fireKeyClassName}>SHIFT</span>
        </p>
      </div>
    </Fragment>
  );
};

const TextControlScheme = (props) => {
  return (
    <S.SchemeWrapper className={props.isHorizontal ? 'horizontal' : undefined}>
      {renderControlScheme(props)}
    </S.SchemeWrapper>
  );
};

export default TextControlScheme;
