import Invader1 from '../../assets/sprites/invaders/1.svg';
import Invader2 from '../../assets/sprites/invaders/2.svg';
import Invader3 from '../../assets/sprites/invaders/3.svg';
import Invader4 from '../../assets/sprites/invaders/4.svg';
import Invader5 from '../../assets/sprites/invaders/5.svg';
import Invader6 from '../../assets/sprites/invaders/6.svg';
import Invader7 from '../../assets/sprites/invaders/7.svg';
import Invader7Blue from '../../assets/sprites/invaders/7-blue.svg';
import Invader8 from '../../assets/sprites/invaders/8.svg';
import Invader9 from '../../assets/sprites/invaders/9.svg';
import Invader9Blue from '../../assets/sprites/invaders/9-blue.svg';
import Invader10 from '../../assets/sprites/invaders/10.svg';
import Invader11 from '../../assets/sprites/invaders/11.svg';
import GuardYellow from '../../assets/sprites/invaders/guard-1.svg';
import GuardBlue from '../../assets/sprites/invaders/guard-2.svg';

import Boss1 from '../../assets/sprites/invaders/boss/boss-1.svg';
import Boss2 from '../../assets/sprites/invaders/boss/boss-2.svg';
import Boss3 from '../../assets/sprites/invaders/boss/boss-3.svg';
import Boss4 from '../../assets/sprites/invaders/boss/boss-4.svg';
import Boss5 from '../../assets/sprites/invaders/boss/boss-5.svg';

const invaderSprites = [
  {
    name: 1,
    src: Invader1,
  },
  {
    name: 2,
    src: Invader2,
  },
  {
    name: 3,
    src: Invader3,
  },
  {
    name: 4,
    src: Invader4,
  },
  {
    name: 51,
    src: Invader5,
  },
  {
    name: 52,
    src: Invader5,
    flipH: true,
  },
  {
    name: 6,
    src: Invader6,
  },
  {
    name: '7-yellow',
    sharedName: 7,
    src: Invader7,
    partial: true,
    stateId: 1,
  },
  {
    name: '7-blue',
    sharedName: 7,
    src: Invader7Blue,
    partial: true,
    stateId: 2,
  },
  {
    name: 8,
    src: Invader8,
  },
  {
    name: '9-yellow',
    sharedName: 9,
    src: Invader9,
    partial: true,
    stateId: 1,
  },
  {
    name: '9-blue',
    sharedName: 9,
    src: Invader9Blue,
    partial: true,
    stateId: 2,
  },
  {
    name: 10,
    src: Invader10,
  },
  {
    name: 11,
    src: Invader11,
  },
  {
    name: 'boss5',
    sharedName: 'boss',
    src: Boss5,
    partial: true,
    stateId: 5,
  },
  {
    name: 'boss4',
    sharedName: 'boss',
    src: Boss4,
    partial: true,
    stateId: 4,
  },
  {
    name: 'boss3',
    sharedName: 'boss',
    src: Boss3,
    partial: true,
    stateId: 3,
  },
  {
    name: 'boss2',
    sharedName: 'boss',
    src: Boss2,
    partial: true,
    stateId: 2,
  },
  {
    name: 'boss1',
    sharedName: 'boss',
    src: Boss1,
    partial: true,
    stateId: 1,
  },
  {
    name: 'guardBlue',
    sharedName: 'guard',
    src: GuardBlue,
    partial: true,
    stateId: 2,
  },
  {
    name: 'guardYellow',
    sharedName: 'guard',
    src: GuardYellow,
    partial: true,
    stateId: 1,
  },
];

export default invaderSprites;
