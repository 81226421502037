import styled from 'styled-components/macro';
import { flexCenter, BaseGameScreenWrapper } from '../../styles/shared-styles';
import { screens } from '../../styles/theme';

export const PauseWrapper = styled(BaseGameScreenWrapper)`
  ${flexCenter}
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  text-align: center;
  font-size: 1.25rem;

  @media (min-width: ${screens.lg}) {
    font-size: 1.5rem;
  }
`;

export const Countdown = styled('div')`
  margin-top: 1rem;
  font-size: 1.5rem;

  &.hidden {
    visibility: hidden;
  }
`;
