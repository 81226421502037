import React from 'react';
import { loadSpriteSheet, loadSprite } from './spriteLoader';
import sprites from './sprites';
import invaderSprites from './invaderSprites';
import objectSizes from '../Game/objectSizes';

const initialState = {
  ready: false,
  sprites: {}
};

export const SpritesContext = React.createContext(initialState);

const getMergedSprites = (sprites) => {
  let merged = {};

  Object.keys(sprites).forEach((spriteName) => {
    const { stateId, sharedName, ...sprite } = sprites[spriteName];

    if (sharedName) {
      merged[sharedName] = {
        ...sprite,
        src: {
          ...(merged[sharedName] || {}).src,
          [stateId]: sprite.src
        }
      };
    } else {
      merged[spriteName] = sprite;
    }
  });

  return merged;
};

function cacheSprite(img, { width, height }) {
  if (!width || !height) return;

  const sizeMultiplier = 4;
  let c = document.createElement('canvas');
  c.width = width * sizeMultiplier;
  c.height = height * sizeMultiplier;
  const ctx = c.getContext('2d');

  ctx.drawImage(img, 0, 0, width * sizeMultiplier, height * sizeMultiplier);

  return c;
}

class SpritesManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.spritesCount = 0;
  }

  componentDidMount() {
    // Load sprites
    sprites.forEach((sprite) => {
      const { src, ...restProps } = sprite;
      if (sprite.isSpriteSheet) {
        loadSpriteSheet(src).then((img) => this.saveSprite(img, restProps));
      } else {
        loadSprite(src).then((img) => this.saveSprite(img, restProps));
      }
    });

    // Load invader sprites
    invaderSprites.forEach((sprite) => {
      const { src, ...restProps } = sprite;
      if (sprite.isStatic) {
        loadSprite(src).then((img) => this.saveSprite(img, restProps));
      } else {
        loadSpriteSheet(src).then((spritesheet) => {
          this.saveInvaderSprites(spritesheet, restProps);
        });
      }
    });

    this.spritesCount = invaderSprites.length + sprites.length;
  }

  componentDidUpdate() {
    if (!this.state.ready) {
      const spritesLoaded =
        -1 +
        Object.keys(this.state.sprites).length +
        Object.keys(this.state.sprites.invaders || {}).length;

      // Once all sprites are loaded, the game is ready to start
      if (spritesLoaded === this.spritesCount) {
        this.setState({
          ready: true,
          sprites: {
            ...this.state.sprites,
            invaders: getMergedSprites(this.state.sprites.invaders)
          }
        });
      }
    }
  }

  saveSprite(img, { name, ...props }) {
    let sprite = img;
    
    if (!props.isSpriteSheet && props.size) {
      sprite = cacheSprite(img, props.size);
    }

    this.setState({
      sprites: {
        ...this.state.sprites,
        [name]: {
          src: sprite,
          ...props
        }
      }
    });
  }

  saveInvaderSprites(imgs, { name, ...props }) {
    const spriteName = props.sharedName || name;
    const spriteSize = objectSizes.invaders[spriteName] || objectSizes.invaders.default;
    let cachedSpritesheet = imgs.map((img) => cacheSprite(img, spriteSize));

    this.setState({
      sprites: {
        ...this.state.sprites,
        invaders: {
          ...(this.state.sprites.invaders || {}),
          [name]: {
            src: cachedSpritesheet,
            ...props
          }
        }
      }
    });
  }

  render() {
    return (
      <SpritesContext.Provider value={this.state}>{this.props.children}</SpritesContext.Provider>
    );
  }
}

export default SpritesManager;
