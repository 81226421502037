import styled from 'styled-components/macro';
import { colors } from '../../styles/theme';

export const SchemeWrapper = styled('div')`
  color: #fff;
  line-height: 2;
  font-size: 1.25rem;
  font-weight: 900;
  margin: 2rem 0 1.5rem;

  p {
    margin: 0;
  }

  span.color-red {
    color: ${colors.red};
  }

  span.sans-serif {
    font-family: sans-serif;
    font-weight: bold;
    display: inline-block;
    min-width: 1em;
  }

  span.fixed-width {
    min-width: 50px;
    display: inline-block;
  }

  &.horizontal {
    margin: auto 0;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0 1rem;
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      p + p {
        padding-left: 1.5rem;
      }
    }
  }
`;
