import Bullet from './Bullet';
import GameObject from './GameObject';
import changeColor from '../../utils/changeColor';

const isFirefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

const FIRE_RATE = 500;
const INITIAL_FLAME_DELTA = 0.7;
const HIT_OVERLAY_COLOR = 'hsl(351, 76%, 50%)';
const UPDATE_MAX_TIMEOUT = 300;

export default class Ship extends GameObject {
  constructor(props) {
    super({
      position: {
        x: props.position.x - props.size.width / 2,
        y: props.position.y
      },
      onDie: props.onDie,
      speed: 12,
      size: props.size
    });
    this.bullets = [];
    this.lastShot = 0;
    this.sprite = props.sprite;
    this.controlScheme = props.controlScheme;
    this.shipName = props.shipName;
    this.isRemote = props.isRemote;
    this.flame = props.flame;
    this.bulletSprite = props.bulletSprite;
    this.lastUpdatedActionsAt = 0;

    // Ongoing actions
    this.movesRight = false;
    this.movesLeft = false;
    this.fires = false;

    if (this.controlScheme === undefined) {
      console.log(`Warning: ship ${this.shipName} doesn't have an assigned control scheme`);
    }

    this.prevState = {
      movesRight: this.movesRight,
      movesLeft: this.movesLeft,
      fires: this.fires,
      x: 0
    };

    this.nextFlameDelta = INITIAL_FLAME_DELTA;
    this.hitFramesCount = 0;

    this.animation = {
      value: 100,
      timeline: [],
      delay: 0,
      step: 1
    };

    if (props.animateSizeOnInit) {
      this.animation = {
        value: 100,
        timeline: [125, 100],
        delay: 10,
        step: 3
      };
    }

    this.startedMovingAt = 0;
    this.startedMovingTs = 0;
  }

  getAnimatedSizeDelta() {
    const { timeline, step } = this.animation;

    if (timeline.length > 0 && this.animation.delay === 0) {
      const targetValue = timeline[0];
      const diff = Math.abs(targetValue - this.animation.value) > step - 1 ? step : 1;
      if (targetValue > this.animation.value) {
        this.animation.value += diff;
      } else if (targetValue < this.animation.value) {
        this.animation.value -= diff;
      }

      if (this.animation.value === targetValue) {
        this.animation.timeline = timeline.slice(1);
      }
    } else if (this.animation.delay > 0) {
      this.animation.delay--;
    }

    return this.animation.value === 100 ? 1 : this.animation.value / 100;
  }

  die(props) {
    if (this.onDie) {
      this.onDie(this.shipName, props);
    }
    this.hitFramesCount = 10;

    this.animation = {
      value: 100,
      timeline: [125, 80, 100],
      delay: 0,
      step: 5
    };
  }

  getCurrentActions() {
    return {
      fire: this.fires,
      left: this.movesLeft,
      right: this.movesRight
    };
  }

  haveActionsUpdated() {
    const { fires, movesLeft, movesRight } = this.prevState;
    return fires !== this.fires || movesLeft !== this.movesLeft || movesRight !== this.movesRight;
  }

  updateActions(actions) {
    // Save previous state
    this.prevState = {
      movesRight: this.movesRight,
      movesLeft: this.movesLeft,
      fires: this.fires
    };

    this.movesRight = actions.right;
    this.movesLeft = actions.left;

    // The ship can't move in both directions
    if (this.movesRight && this.movesLeft) {
      this.movesLeft = false;
    }

    this.fires = actions.fire;
    this.lastUpdatedActionsAt = Date.now();
  }

  update(dt = 1, now) {
    this.prevState.x = this.position.x;

    if (now - this.lastUpdatedActionsAt > UPDATE_MAX_TIMEOUT) {
      this.movesRight = false;
      this.movesLeft = false;
      this.fires = false;

      return;
    }

    if (this.movesRight) {
      this.moveRight(dt);
    } else if (this.movesLeft) {
      this.moveLeft(dt);
    }

    if (this.fires) {
      this.fire();
    }
  }

  applyUpdate({ actions, position }) {
    if (actions) {
      this.updateActions(actions);
    }

    if (position) {
      // console.log(this.position.x, position.x)
      this.position.x = position.x;
    }
  }

  moveLeft(dt) {
    this.position.x -= this.speed * dt;
  }

  moveRight(dt) {
    this.position.x += this.speed * dt;
  }

  fire() {
    if (Date.now() - this.lastShot <= FIRE_RATE) {
      return false;
    }
    const bullet = new Bullet({
      position: {
        x: this.position.x + this.size.width / 2,
        y: this.position.y
      },
      direction: 'up',
      firedBy: this.shipName,
      isRemote: this.isRemote,
      getSprite: this.getBulletSprite
    });

    this.bullets.push(bullet);
    this.lastShot = Date.now();
    return true;
  }

  getBulletSprite = () => {
    return this.bulletSprite;
  }

  renderBullets(state, dt) {
    this.bullets = this.bullets.filter((bullet) => {
      if (bullet.dead) {
        return false;
      }

      bullet.update();
      bullet.render(state);
      
      return true;
    });
  }

  checkBounds(screen) {
    if (this.position.x > screen.width) {
      this.position.x = 0;
    } else if (this.position.x < 0) {
      this.position.x = screen.width;
    }
  }

  renderFlame(context, sizeDelta) {
    const flameSize = this.flame.size;
    const flameDelta = this.nextFlameDelta * sizeDelta;

    context.drawImage(
      this.flame.sprite.src,
      (-flameSize.width * flameDelta) / 2,
      (this.size.height * sizeDelta) / 2 + flameSize.gutter,
      flameSize.width * flameDelta,
      flameSize.height * flameDelta
    );

    this.nextFlameDelta =
      this.nextFlameDelta >= 1 ? INITIAL_FLAME_DELTA : this.nextFlameDelta + 0.15;
  }

  getSprite() {
    let sprite = this.sprite.src;
    if (this.hitFramesCount > 0) {
      // Don't recolor the ship in Firefox due to a canvax coloring bug
      if (!isFirefox) {
        sprite = changeColor(sprite, HIT_OVERLAY_COLOR);
      }
      this.hitFramesCount--;
    }
    return sprite;
  }

  hasActiveActions() {
    return this.movesRight || this.movesLeft || this.fires;
  }

  serialize() {
    const haveActionsUpdated = this.haveActionsUpdated();

    if (this.hasActiveActions() || haveActionsUpdated) {
      let state = {
        actions: this.getCurrentActions()
      };

      if (haveActionsUpdated) {
        state.position = {
          x: this.prevState.x
        };
      }

      return state;
    }

    return undefined;
  }

  render(state, dt) {
    const sizeDelta = this.getAnimatedSizeDelta();

    this.checkBounds(state.screen);

    this.renderBullets(state, dt);

    const context = state.context;
    context.save();

    context.translate(
      this.position.x + this.size.width / 2,
      this.position.y + this.size.height / 2
    );

    const renderedWidth = this.size.width * sizeDelta,
      renderedHeight = this.size.height * sizeDelta;

    context.drawImage(
      this.getSprite(),
      -renderedWidth / 2,
      -renderedHeight / 2,
      renderedWidth,
      renderedHeight
    );

    this.renderFlame(context, sizeDelta);

    context.restore();
  }
}
