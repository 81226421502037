import config from '../../config';

export const getScores = (type) => {
  return fetch(`${config.apiEndpoint}/highscore?type=${type}`)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject();
      }
      return response.json();
    })
    .then((json) => {
      return json ? json.scores : [];
    });
};

export const saveScore = (data) => {
  return fetch(`${config.apiEndpoint}/highscore`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((response) => {
    if (response.status !== 200) {
      return false;
    }
    return true;
  });
};
