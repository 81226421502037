import React, { Component } from 'react';
import cc from 'classcat';
import equal from 'fast-deep-equal';

import GameStartScreen from '../GameStartScreen/GameStartScreen';
import GameIntro from '../GameIntro/GameIntro';
import GameOverScreen from '../GameOverScreen/GameOverScreen';
import HighscoreScreen from '../HighscoreScreen/HighscoreScreen';
import PauseScreen from '../PauseScreen/PauseScreen';
import ControlsColumn from '../ControlsColumn/ControlsColumn';
import PlayerStats from '../PlayerStats/PlayerStats';
import GameHeader from '../GameHeader/GameHeader';
import InputManager from '../InputManager';
import Game from '../Game/Game';
import GameUI from '../GameUI';
import WindowSize from '../WindowSize';
import GameCutscene from '../GameCutscene/GameCutscene';
import { SpritesContext } from '../SpritesManager/SpritesManager';
import { isScreenPortraitMobile, supportsTouch } from '../../utils/browser';
import { BackgroundWrapper } from '../../styles/shared-styles';
import * as S from './styles';
import {
  topOffset,
  GameState,
  GameServerState,
  gameSettings,
  internalScreenSize
} from './gameConfig';
import { uiSizing } from '../../styles/theme';

const getWaveBgWidth = ({ width, height }) => {
  // 15.75 = original waveWidth / waveHeight
  // 1.5 is the default background size width of the wave (150%)
  let waveHeight = (width * 1.5) / 15.75;
  let wavesCount = Math.floor(height / waveHeight);
  waveHeight = height / wavesCount;
  let percentage = (waveHeight / (width / 15.75)) * 100;

  return percentage.toFixed(2);
};

const getScaledGameScreenSize = (scaleRatio, isMobile) => {
  let height = internalScreenSize.height * scaleRatio;
  height += isMobile ? topOffset.mobile : topOffset.desktop;
  const width = internalScreenSize.width * scaleRatio;

  return { height, width };
};

const getScaleRatio = (screen) => {
  const scaleRatioY = screen.height / internalScreenSize.height;
  const scaleRatioX = screen.width / internalScreenSize.width;

  return scaleRatioY > scaleRatioX ? scaleRatioX : scaleRatioY;
};

const getGameScreen = (isMobile, hasTouchSupport, spectatorMode) => {
  let scaleRatio,
    realScreen = {
      width: window.innerWidth,
      height: window.innerHeight
    };

  if (spectatorMode) {
    realScreen.height -= isMobile ? topOffset.mobile : topOffset.desktop;
    scaleRatio = getScaleRatio(realScreen);

    return {
      pixelRatio: window.devicePixelRatio * scaleRatio || 1,
      scaleRatio,
      mode: 'spectator',
      ...internalScreenSize,
      scaledSize: getScaledGameScreenSize(scaleRatio, isMobile)
    };
  }

  if (isMobile) {
    if (realScreen.width < 375) {
      realScreen.height -= uiSizing.mobileTouchControlsHeight + 21 + topOffset.mobile;
      realScreen.width -= 14;
    } else {
      realScreen.height -= uiSizing.mobileTouchControlsHeight + 21 + 81 + topOffset.mobile;
      realScreen.width -= 40;
    }
  } else if (hasTouchSupport) {
    realScreen.width -= uiSizing.tabletTouchControlsWidth * 2;
    realScreen.height -= topOffset.desktop;
  } else {
    realScreen.height -= topOffset.desktop;
  }

  scaleRatio = getScaleRatio(realScreen);

  // Find the best fitting UI mode
  let mode;

  if (isMobile) {
    mode = 'mobile';
  } else if (hasTouchSupport) {
    const gameScreenIsFullHeight =
      Math.abs(realScreen.height - internalScreenSize.height * scaleRatio) < 20;
    mode = gameScreenIsFullHeight ? 'tabletHorizontal' : 'tabletVertical';
  } else {
    const gameScreenWidth = internalScreenSize.width * scaleRatio;
    const columnsFit = realScreen.width - uiSizing.gameColumnWidth * 2 >= gameScreenWidth;
    mode = columnsFit ? 'desktopHorizontal' : 'desktopVertical';
  }

  // If vertical mode is chosen, the scale ratio has to be calculated
  // to accomadate for the game header on top
  if (mode === 'desktopVertical') {
    realScreen.height -= uiSizing.gameHeaderHeight;
    scaleRatio = getScaleRatio(realScreen);
  } else if (mode === 'tabletVertical') {
    realScreen.width += uiSizing.tabletTouchControlsWidth * 2;
    realScreen.height -= uiSizing.tabletTouchControlsHeight;
    scaleRatio = getScaleRatio(realScreen);
  }

  let screen = {
    pixelRatio: window.devicePixelRatio * scaleRatio || 1,
    scaleRatio,
    mode,
    ...internalScreenSize,
    scaledSize: getScaledGameScreenSize(scaleRatio, isMobile)
  };

  return screen;
};

const getPlayerCount = (props) => {
  let playerCount = props.playerCount || props.match.params.playerCount;
  playerCount = parseInt(playerCount, 10);
  if (playerCount !== 1 && playerCount !== 2) {
    playerCount = 1;
  }
  return playerCount;
};

function shouldApplyGameUpdate(nextGameUpdate, state) {
  if (!nextGameUpdate) {
    return false;
  }

  if (
    nextGameUpdate.lvl !== undefined ||
    (nextGameUpdate.score && !equal(nextGameUpdate.score, state.score)) ||
    (nextGameUpdate.livesCount && !equal(nextGameUpdate.livesCount, state.livesCount)) ||
    nextGameUpdate.serverState === GameServerState.Over
  ) {
    return true;
  }

  return false;
}

class GameManager extends Component {
  constructor(props) {
    super(props);

    const isMobile = isScreenPortraitMobile();
    const hasTouchSupport = supportsTouch();
    const shipsCount = getPlayerCount(props);
    const screen = getGameScreen(isMobile, hasTouchSupport, props.spectatorMode);

    this.state = {
      gameState: gameSettings.initialGameState,
      score: gameSettings.initialScore,
      shipsCount,
      livesCount: {
        1: gameSettings.initialLivesCount,
        2: shipsCount > 1 ? gameSettings.initialLivesCount : 0
      },
      pressedButtons: gameSettings.initialPressedButtons,
      screen,
      windowSize: {},
      isMobile,
      hasTouchSupport,
      playerReady: gameSettings.initialPlayerReady,
      scoreSaved: false,
      gamePaused: false,
      waveBgWidth: getWaveBgWidth(screen.scaledSize),
      animateVictory: true
    };

    this.initialLevel = gameSettings.initialLevel;
    this.inputManager = new InputManager();
    this.startedAt = null;
  }

  componentDidMount() {
    this.inputManager.bindKeys();

    if (this.props.isRemote && this.props.generalGameUpdate !== null) {
      this.onGeneralGameUpdate(this.props.generalGameUpdate);

      // Check if the game has already progressed to another level
      if (
        this.props.generalGameUpdate.lvl &&
        this.props.generalGameUpdate.lvl > this.initialLevel
      ) {
        this.initialLevel = this.props.generalGameUpdate.lvl;
      }

      // If the game is already over, bail early, as there's no need to start it
      if (this.props.generalGameUpdate.serverState === GameServerState.Over) {
        return;
      }
    }

    if (this.props.readyToPlay || (this.state.playerReady && !this.props.spectatorMode)) {
      this.startGame();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state !== nextState ||
      (nextProps.generalGameUpdate !== this.props.generalGameUpdate &&
        shouldApplyGameUpdate(nextProps.generalGameUpdate, this.state)) ||
      nextProps.readyToPlay !== this.props.readyToPlay ||
      nextProps.playerId !== this.props.playerId
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.inputManager.unbindKeys();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isRemote) return;

    if (prevProps.generalGameUpdate !== this.props.generalGameUpdate) {
      this.onGeneralGameUpdate(this.props.generalGameUpdate);
    }

    if (this.props.readyToPlay && !prevProps.readyToPlay) {
      this.startGameIntro();
    }
  }

  onGeneralGameUpdate(update) {
    const { score, livesCount } = update;

    if (score && !equal(score, this.state.score)) {
      this.setState({ score });
    }

    if (livesCount && !equal(livesCount, this.state.livesCount)) {
      this.mergeRemoteLivesCount(livesCount);
    }

    if (update.serverState === GameServerState.Over) {
      this.onGameOver();
    }
  }

  onStartGameRequest = () => {
    if (this.props.spectatorMode) return;

    if (this.props.isRemote && this.props.onPlayerReadyToPlay) {
      this.props.onPlayerReadyToPlay();
    } else {
      this.startGameIntro();
    }

    this.setState({ playerReady: true });
  };

  mergeRemoteLivesCount(livesCount) {
    this.setState({
      livesCount: {
        ...this.state.livesCount,
        ...livesCount
      }
    });
  }

  onResize = (windowSize) => {
    const isMobile = isScreenPortraitMobile();
    let nextScreen = getGameScreen(isMobile, this.state.hasTouchSupport, this.props.spectatorMode);

    this.setState({
      screen: nextScreen,
      windowSize,
      isMobile,
      waveBgWidth: getWaveBgWidth(nextScreen.scaledSize)
    });
  };

  startGameIntro() {
    this.setState({ gameState: GameState.Intro });
  }

  startGame = () => {
    this.inputManager.enable();
    this.startedAt = Date.now();

    this.setState({
      gameState: GameState.Playing
    });
  };

  restartLocalGame() {
    this.initialLevel = gameSettings.initialLevel;
    this.inputManager.enable();
    this.startedAt = Date.now();

    this.setState({
      gameState: GameState.Playing,
      score: gameSettings.initialScore,
      livesCount: {
        1: gameSettings.initialLivesCount,
        2: this.state.shipsCount > 1 ? gameSettings.initialLivesCount : 0
      },
      pressedButtons: gameSettings.initialPressedButtons,
      scoreSaved: false,
      animateVictory: true
    });
  }

  onGameOver = () => {
    if (this.state.gameState === GameState.GameOver) return;

    this.inputManager.disable();

    this.setState({
      gameState: GameState.GameOver
    });
  };

  onContinueClick = () => {
    this.props.history.push('/');
  };

  getScoreValue(baseScoreValue = 500) {
    // Negative values are left untouched
    if (baseScoreValue < 0) return baseScoreValue;

    const secondsPassed = Math.round((Date.now() - this.startedAt) / 1000);
    const scoreValue = Math.max(baseScoreValue - secondsPassed * 10, 100);

    return scoreValue;
  }

  onLocalScoreChange = (shipName, baseScoreValue) => {
    const nextScore = {
      ...this.state.score,
      [shipName]: this.state.score[shipName] + this.getScoreValue(baseScoreValue)
    };
    this.setState({ score: nextScore });
  };

  onLivesCountChange = (livesCount, shipName) => {
    this.setState({ livesCount });

    if (!this.props.isRemote) {
      // Each lost life lowers the score
      this.onLocalScoreChange(shipName, -1000);
    }

    if (!this.props.isRemote || this.props.spectatorMode) return;

    if (shipName === undefined) {
      this.props.onGameUpdate({
        livesCount
      });
    } else {
      this.props.onGameUpdate({
        livesCount: {
          [shipName]: livesCount[shipName]
        }
      });
    }
  };

  setPressedButtons = (pressedButtons) => {
    this.setState({ pressedButtons });
  };

  onLevelComplete = () => {
    this.setState({ gamePaused: true });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState({ gamePaused: false }, resolve);
      }, 5000);
    });
  };

  onSaveScoreRequest = () => {
    this.setState({
      gameState: GameState.Highscore
    });
  };

  onGameOverScreenRequest = () => {
    this.setState({
      gameState: GameState.GameOver,
      animateVictory: false
    });
  };

  onScoreSaved = () => {
    this.setState({ gameState: GameState.GameOver, scoreSaved: true });
  };

  onAgainClick = () => {
    if (this.props.spectatorMode) return;

    if (this.props.isRemote) {
      if (this.props.onGameResetRequest) {
        this.props.onGameResetRequest();
      } else {
        this.props.history.push('/');
      }
    } else {
      this.restartLocalGame();
    }
  };

  onCutsceneEnded = () => {
    this.setState({ gameState: GameState.Init });
  };

  getVisibleScore() {
    const { score } = this.state;

    return {
      1: score[1] < 0 ? 0 : score[1],
      2: score[2] < 0 ? 0 : score[2]
    };
  }

  render() {
    const { gameState, shipsCount, livesCount, screen } = this.state;
    const visibleScore = this.getVisibleScore();
    const gameScreenStyle = {
      ...screen.scaledSize,
      minWidth: screen.scaledSize.width
    };
    const isPlaying = gameState === GameState.Playing;
    const spritesData = this.context;
    const isHorizontalMode = screen.mode === 'desktopHorizontal';
    const isVerticalMode = screen.mode === 'desktopVertical';
    const isTabletMode = screen.mode.indexOf('tablet') > -1;

    if (!spritesData.ready && isPlaying) {
      return null;
    }

    return (
      <BackgroundWrapper minHeight={this.state.windowSize.height}>
        <WindowSize onChange={this.onResize} />
        <S.GameContainer className={cc([{ isPlaying }, `screen-${screen.mode}`])}>
          {isVerticalMode && (
            <GameHeader
              history={this.props.history}
              maxWidth={gameScreenStyle.width}
              controlScheme={this.props.isRemote || shipsCount === 1 ? 'any' : 'both'}
            />
          )}
          {isHorizontalMode && (
            <ControlsColumn
              score={visibleScore[1]}
              playerNumber={this.props.playerId || 1}
              livesCount={livesCount[1]}
              controlScheme={this.props.isRemote || shipsCount === 1 ? 'any' : 'left'}
              displayPlayerScore={!this.props.isRemote}
            />
          )}
          <GameUI
            mode={screen.mode}
            onControlsChange={this.setPressedButtons}
            pressedButtons={this.state.pressedButtons}
            screen={screen}
            passThrough={gameState === GameState.Cutscene || gameState === GameState.Highscore}
          >
            {gameState === GameState.Cutscene && (
              <GameCutscene
                isInline={screen.mode !== 'mobile'}
                style={gameScreenStyle}
                onEnded={this.onCutsceneEnded}
              />
            )}
            {gameState === GameState.Init && (
              <GameStartScreen
                style={gameScreenStyle}
                onGameStartRequest={this.onStartGameRequest}
                playerReady={this.state.playerReady}
                spectatorMode={this.props.spectatorMode}
              />
            )}
            {gameState === GameState.Intro && (
              <GameIntro style={gameScreenStyle} onIntroEnded={this.startGame} />
            )}
            {gameState === GameState.GameOver && (
              <GameOverScreen
                style={gameScreenStyle}
                score={visibleScore}
                shipsCount={shipsCount}
                livesCount={livesCount}
                onSaveScoreClick={this.onSaveScoreRequest}
                onAgainClick={this.onAgainClick}
                onContinueClick={this.onContinueClick}
                displayAdditionalOptions={!this.props.spectatorMode}
                selfPlayerId={this.props.playerId}
                highlightSelf={this.props.isRemote}
                scoreSaved={this.state.scoreSaved}
                animateVictory={this.state.animateVictory}
              />
            )}
            {gameState === GameState.Highscore && (
              <HighscoreScreen
                inGame={true}
                gameScore={visibleScore}
                gameType={shipsCount}
                playerCount={this.props.isRemote ? 1 : shipsCount}
                initialPlayerId={this.props.isRemote ? this.props.playerId : 1}
                style={screen.mode !== 'mobile' ? gameScreenStyle : undefined}
                onScoreSaved={this.onScoreSaved}
                onBackClick={this.onGameOverScreenRequest}
              />
            )}
            <S.GameScreenWrapper
              waveBgWidth={this.state.waveBgWidth}
              className={cc(['game-screen-wrapper', { animated: gameSettings.animateBackground }])}
            >
              <PlayerStats
                className={cc({
                  'always-visible': this.props.isRemote || isVerticalMode || isTabletMode
                })}
                score={visibleScore}
                livesCount={livesCount}
                shipsCount={shipsCount}
              />
              {this.state.gamePaused && <PauseScreen />}
              {isPlaying && (
                <Game
                  initialLevel={this.initialLevel}
                  enableShipDeath={gameSettings.enableShipDeath}
                  isRemote={this.props.isRemote}
                  onUpdate={this.props.onGameUpdate}
                  playerId={this.props.playerId}
                  sprites={spritesData.sprites}
                  shipsCount={shipsCount}
                  isPlaying={isPlaying}
                  screen={screen}
                  onGameOver={this.onGameOver}
                  livesCount={livesCount}
                  onLocalScoreChange={this.onLocalScoreChange}
                  onLivesCountChange={this.onLivesCountChange}
                  pressedButtons={this.state.pressedButtons}
                  pressedKeys={this.inputManager.pressedKeys}
                  spectatorMode={this.props.spectatorMode}
                  onLevelComplete={this.onLevelComplete}
                />
              )}
            </S.GameScreenWrapper>
          </GameUI>
          {isHorizontalMode && shipsCount === 2 && !this.props.isRemote ? (
            <ControlsColumn
              score={visibleScore[2]}
              playerNumber={2}
              livesCount={livesCount[2]}
              controlScheme="right"
              displayPlayerScore={!this.props.isRemote}
            />
          ) : (
            <S.PlaceholderColumn className="game-column" />
          )}
        </S.GameContainer>
      </BackgroundWrapper>
    );
  }
}

GameManager.defaultProps = {
  isRemote: false
};

GameManager.contextType = SpritesContext;

export default GameManager;
