export default class GameObject {
  constructor(props) {
    this.position = props.position;
    this.onDie = props.onDie;
    this.speed = props.speed;
    this.size = props.size;
    this.dead = false;
  }

  die(props) {
    this.dead = true;
    if (this.onDie) {
      this.onDie(props);
    }
  }

  render(state) {
    if (this.position.x > state.screen.width) {
      this.position.x = 0;
    } else if (this.position.x < 0) {
      this.position.x = state.screen.width;
    }
    if (this.position.y > state.screen.height) {
      this.position.y = 0;
    } else if (this.position.y < 0) {
      this.position.y = state.screen.height;
    }
  }
}
