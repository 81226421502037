import React from 'react';
import WindowSize from '../WindowSize';
import InputListener from '../InputListener';
import cutsceneData from './cutsceneData';
import * as S from './styles';

const DESKTOP_WIDTH = 600;

class GameCutscene extends React.Component {
  constructor() {
    super();

    const screen = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.state = {
      displayFullCutscene: screen.width >= DESKTOP_WIDTH,
      currentSceneId: 0,
      videoSrc: null,
      screen
    };
  }

  componentDidMount() {
    this.setVideoSource();
  }

  setVideoSource() {
    this.setState(
      {
        videoSrc: this.state.displayFullCutscene
          ? cutsceneData.full
          : cutsceneData.scenes[this.state.currentSceneId]
      },
      () => {
        if (this.videoEl) this.videoEl.load();
      }
    );
  }

  onResize = (screen) => {
    this.setState({ screen });

    const nextDisplayFullCutscene = screen.width >= DESKTOP_WIDTH;
    if (nextDisplayFullCutscene === this.state.displayFullCutscene) return;

    this.setState({ displayFullCutscene: nextDisplayFullCutscene }, () => {
      this.setVideoSource();
    });
  };

  onSkip = (event) => {
    if (event) {
      event.preventDefault();
    }
    this.props.onEnded && this.props.onEnded();
  };

  onNextScene = (event) => {
    if (event) {
      event.preventDefault();
    }

    const nextSceneId = this.state.currentSceneId + 1;

    if (nextSceneId < cutsceneData.scenes.length) {
      this.setState({ currentSceneId: nextSceneId }, () => {
        this.setVideoSource();
      });
    }
  };

  onEnded = () => {
    if (
      this.state.displayFullCutscene ||
      this.state.currentSceneId === cutsceneData.scenes.length - 1
    ) {
      this.onSkip();
    } else {
      this.onNextScene();
    }
  };

  render() {
    return (
      <S.IntroWrapper
        className={this.props.isInline ? 'inline' : undefined}
        style={this.props.isInline ? this.props.style : this.state.screen}
      >
        <WindowSize onChange={this.onResize} />
        <InputListener onFireButton={this.onSkip} />
        <S.VideoWrapper>
          {this.state.videoSrc && (
            <video
              onEnded={this.onEnded}
              ref={(el) => (this.videoEl = el)}
              playsInline
              autoPlay
              muted
              tabIndex="-1"
            >
              <source src={this.state.videoSrc} type="video/mp4" />
            </video>
          )}
        </S.VideoWrapper>
        <S.ButtonsList>
          <S.TextButton onClick={this.onSkip}>
            <span className="mobile-only">SKIP</span>
            <span className="desktop-only">PRESS FIRE TO SKIP</span>
          </S.TextButton>
          <S.TextButton className="mobile-only" onClick={this.onNextScene}>
            <span>NEXT</span>
          </S.TextButton>
        </S.ButtonsList>
      </S.IntroWrapper>
    );
  }
}

export default GameCutscene;
