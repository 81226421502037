import React, { useState, useEffect } from 'react';
import cc from 'classcat';
import { saveScore, getScores } from './highscoreApi';
import { padNumber } from '../../utils/pad';
import Logo from '../../assets/images/logo.png';
import * as S from './styles';

function HighscoreScreen({
  inGame,
  initialPlayerId = 1,
  style,
  gameScore = {},
  playerCount = 1,
  gameType = 1,
  onScoreSaved,
  onBackClick,
  history,
}) {
  const [playerName, setPlayerName] = useState('');
  const [scores, setScores] = useState([]);
  const [playerId, setPlayerId] = useState(initialPlayerId);
  const [displayedGameType, setDisplayedGameType] = useState(gameType);

  useEffect(() => {
    getScores(displayedGameType)
      .then((results) => {
        setScores(results);
      })
      .catch(() => {
        setScores([]);
      });
  }, [displayedGameType]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (playerName.length !== 3) {
      return;
    }

    saveScore({
      name: playerName,
      score: gameScore[playerId],
      gameType,
    }).then(() => {
      if (playerCount > 1 && playerId < playerCount) {
        setPlayerId(2);
        setPlayerName('');
      } else if (onScoreSaved) {
        onScoreSaved();
      }
    });
  };

  const handleInputChange = (event) => {
    const nextValue = event.target.value;

    if (nextValue.length > 3 || nextValue.indexOf(' ') > -1) {
      return;
    }

    setPlayerName(event.target.value);
  };

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else if (history) {
      history.push('/');
    }
  };

  if (inGame && isNaN(gameScore[playerId])) {
    console.log('Missing player scores', gameScore);
    return null;
  }

  return (
    <S.HighscoreWrapper className={cc({ ingame: inGame })} style={style}>
      <img onClick={handleBackClick} className="logo" src={Logo} alt="Logo" />
      {!inGame && (
        <S.Tabs>
          {[1, 2].map((id) => (
            <S.Tab
              key={id}
              className={cc({ active: displayedGameType === id })}
              onClick={() => setDisplayedGameType(id)}
            >
              {id}P
            </S.Tab>
          ))}
        </S.Tabs>
      )}

      {scores.length > 0 && (
        <S.Table>
          <S.TableRow className="small">
            <S.TableColumn className="position">Position</S.TableColumn>
            <S.TableColumn className="player-name">Name</S.TableColumn>
            <S.TableColumn className="player-score">Score</S.TableColumn>
          </S.TableRow>
          {scores.map((row, index) => (
            <S.TableRow key={index}>
              <S.TableColumn className="position">{index + 1}.</S.TableColumn>
              <S.TableColumn className="player-name">{row.name}</S.TableColumn>
              <S.TableColumn className="player-score">{padNumber(row.score, 7)}</S.TableColumn>
            </S.TableRow>
          ))}
        </S.Table>
      )}

      <S.OverlayWrapper className="overlay-wrapper">
        <S.OverlayContent>
          {inGame && !isNaN(gameScore[playerId]) && (
            <S.ScoreForm onSubmit={handleSubmit}>
              <label className="small">{playerCount > 1 ? `P${playerId}` : 'Your'} Score</label>
              <div className="current-player-score">{padNumber(gameScore[playerId], 7)}</div>
              <label htmlFor="playerName" className="small">
                Enter Name
              </label>
              <div className="field-wrapper">
                <input
                  name="playerName"
                  onChange={handleInputChange}
                  id="playerName"
                  type="text"
                  value={playerName}
                  placeholder="AAA"
                />
              </div>
              <S.Button disabled={playerName.length !== 3}>Save</S.Button>
            </S.ScoreForm>
          )}
          <S.Button className="smaller" onClick={handleBackClick}>
            Back
          </S.Button>
        </S.OverlayContent>
      </S.OverlayWrapper>
    </S.HighscoreWrapper>
  );
}

export default HighscoreScreen;
