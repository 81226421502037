import React, { Fragment } from 'react';
import cc from 'classcat';
import LivesCount from '../LivesCount/LivesCount';
import { padNumber } from '../../utils/pad';
import * as S from './styles';

function PlayerStats(props) {
  const renderRow = (playerName, score, livesCount, rowClassName) => {
    return (
      <S.StatsRow className={rowClassName}>
        <S.TextBlock>
          {playerName}SCORE: <span className="color-red">{padNumber(score, 7)}</span>
        </S.TextBlock>
        <S.TextBlock className="flex">
          {livesCount > 0 ? (
            <Fragment>
              <span className="lives-label">LIVES: </span>
              <LivesCount count={livesCount} lifeClassName="is-small" />
            </Fragment>
          ) : (
            'DEAD'
          )}
        </S.TextBlock>
      </S.StatsRow>
    );
  };
  const isMultiColumn = props.shipsCount > 1;
  const rowClassName = isMultiColumn ? 'flex-column' : undefined;

  return (
    <S.StatsWrapper className={cc([props.className, { 'flex-row': isMultiColumn }])}>
      {renderRow(
        isMultiColumn ? 'P1 ' : undefined,
        props.score[1],
        props.livesCount[1],
        rowClassName
      )}
      {isMultiColumn && renderRow('P2 ', props.score[2], props.livesCount[2], rowClassName)}
    </S.StatsWrapper>
  );
}

export default React.memo(PlayerStats);
