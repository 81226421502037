import ShipP1 from '../../assets/sprites/ship-p1.svg';
import ShipP2 from '../../assets/sprites/ship-p2.svg';
import Flame from '../../assets/sprites/flame.svg';
import BulletEnemy1 from '../../assets/sprites/bullets/bullet-enemy-1.svg';
import BulletEnemy2 from '../../assets/sprites/bullets/bullet-enemy-2.svg';
import BulletEnemy3 from '../../assets/sprites/bullets/bullet-enemy-3.svg';
import BulletShip from '../../assets/sprites/bullets/bullet-ship.svg';
import Explosion from '../../assets/sprites/explosion.svg';

import objectSizes from '../Game/objectSizes';

const sprites = [
  {
    name: 'ship',
    src: ShipP1,
    size: objectSizes.ship,
  },
  {
    name: 'shipAlt',
    src: ShipP2,
    size: objectSizes.ship,
  },
  {
    name: 'flame',
    src: Flame,
    size: objectSizes.flame,
  },
  {
    name: 'bulletEnemyNormal',
    src: BulletEnemy1,
    size: objectSizes.bullets.default,
  },
  {
    name: 'bulletEnemyFast',
    src: BulletEnemy2,
    size: objectSizes.bullets.fast,
  },
  {
    name: 'bulletBoss',
    src: BulletEnemy3,
    size: objectSizes.bullets.default,
  },
  {
    name: 'bulletShip',
    src: BulletShip,
    size: objectSizes.bullets.ship,
  },
  {
    name: 'explosion',
    src: Explosion,
    isSpriteSheet: true,
  },
];

export default sprites;
