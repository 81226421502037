import React from 'react';
import cc from 'classcat';
import * as S from './styles';
import { ReactComponent as LeftButtonIcon } from '../../assets/icons/ui/left-button.svg';
import { ReactComponent as RightButtonIcon } from '../../assets/icons/ui/right-button.svg';
import { ReactComponent as FireButtonIcon } from '../../assets/icons/ui/fire-button.svg';

function TouchControls(props) {
  const setControl = (name, value, e) => {
    e.preventDefault();

    const pressedButtons = {
      ...props.pressedButtons,
      [name]: value
    };
    props.onChange && props.onChange(pressedButtons);
  };

  const onMoveLeftStart = (e) => {
    setControl('left', true, e);
  };

  const onMoveLeftEnd = (e) => {
    setControl('left', false, e);
  };

  const onMoveRightStart = (e) => {
    setControl('right', true, e);
  };

  const onMoveRightEnd = (e) => {
    setControl('right', false, e);
  };

  const onFireStart = (e) => {
    setControl('fire', true, e);
  };

  const onFireEnd = (e) => {
    setControl('fire', false, e);
  };

  return (
    <S.ControlsWrapper className={props.className}>
      {props.activeControls.left && (
        <S.ControlButton
          className={cc(['button-left', { active: props.pressedButtons.left }])}
          type="button"
          onTouchStart={onMoveLeftStart}
          onTouchEnd={onMoveLeftEnd}
          onMouseDown={onMoveLeftStart}
          onMouseUp={onMoveLeftEnd}
        >
          <LeftButtonIcon />
        </S.ControlButton>
      )}
      {props.activeControls.right && (
        <S.ControlButton
          className={cc(['button-right', { active: props.pressedButtons.right }])}
          type="button"
          onTouchStart={onMoveRightStart}
          onTouchEnd={onMoveRightEnd}
          onMouseDown={onMoveRightStart}
          onMouseUp={onMoveRightEnd}
        >
          <RightButtonIcon />
        </S.ControlButton>
      )}
      {props.activeControls.fire && (
        <S.ControlButton
          className={cc(['button-fire', { active: props.pressedButtons.fire }])}
          type="button"
          onTouchStart={onFireStart}
          onTouchEnd={onFireEnd}
          onMouseDown={onFireStart}
          onMouseUp={onFireEnd}
        >
          <FireButtonIcon />
        </S.ControlButton>
      )}
    </S.ControlsWrapper>
  );
}

export default TouchControls;
