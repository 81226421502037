import styled from 'styled-components/macro';
import { BaseTextBlock } from '../../styles/shared-styles';
import { uiSizing } from '../../styles/theme'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-width: ${uiSizing.gameColumnWidth}px;
  margin: 0 auto;

  .spaceman {
    display: none;
    max-height: 200px;
    margin: 2rem auto 0;

    &.always-visible {
      display: block;
    }
  }

  @media (min-height: 890px) {
    .spaceman {
      display: block;
    }
  }
`;

export const Logo = styled('div')`
  max-width: 250px;
  margin: 2rem auto 1rem;
`;

export const PlayerStats = styled('div')`
  margin-top: 1rem;
`

export const TextBlock = styled(BaseTextBlock)`
  font-size: 1.5rem;
  text-align: center;
`;

export const PlayerLives = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 1rem;

  > .lives-label {
    margin-right: 1rem;
  }
`;
