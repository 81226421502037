import styled, { keyframes } from 'styled-components/macro';
import { flexCenter, BaseButton } from '../../styles/shared-styles';
import { colors, screens } from '../../styles/theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 25px 0;

  .p-relative {
    position: relative;
  }

  @media (min-width: ${screens.sm}) {
    flex: 1;
  }

  @media (min-width: ${screens.lg}) {
    padding: 50px;

    > div {
      width: 50%;
    }
  }
`;

export const LeftCol = styled('div')`
  padding-right: 100px;
  display: none;

  @media (min-width: ${screens.lg}) {
    display: block;
  }
`;

export const RightCol = styled('div')`
  ${flexCenter}

  .spaceman-mobile {
    margin: 2.5rem 0;
    max-height: 280px;
    max-width: 80%;
  }

  .logo {
    margin-bottom: 70px;
  }

  @media (min-width: ${screens.lg}) {
    .spaceman-mobile {
      display: none;
    }
    .logo {
      margin-bottom: 150px;
      max-width: 400px;
    }
  }
`;

export const GameModes = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;

  > button:not(:last-child) {
    margin-right: 10px;
  }

  .mode-2p {
    display: none;
  }

  .hidden {
    display: none !important;
  }

  @media (min-width: ${screens.sm}) {
    .mode-2p {
      display: flex;
    }
  }

  @media (min-width: ${screens.lg}) {
    > button:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const ModeButton = styled(BaseButton)`
  ${flexCenter}
  width: 95px;
  height: 95px;
  background-color: ${colors.red};
  position: relative;
  color: #fff;
  font-size: 1.125rem;
  letter-spacing: 0.5px;
  line-height: 1.25;
  text-transform: uppercase;

  .ship-icon {
    max-width: 73px;
  }

  .icon {
    height: 28px;
    width: auto;
  }

  span {
    margin-top: 0.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border: 3px solid #fff;
  }

  &:disabled {
    opacity: 0.8;
    cursor: progress;
  }

  @media (min-width: ${screens.lg}) {
    font-size: 1.5rem;
    width: 150px;
    height: 150px;

    span {
      margin-top: 1rem;
    }

    .icon {
      height: 36px;
    }
  }

  @media (min-width: ${screens.xl}) {
    width: 200px;
    height: 200px;
    font-size: 2rem;

    .icon {
      height: 55px;
    }
  }
`;

export const Footer = styled('footer')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;

  a {
    margin-top: 0;
    font-size: 12px;
  }

  .at-left {
    margin-left: 0;
  }

  .at-right {
    margin-left: auto;
  }

  a.at-right + a {
    margin-left: 20px;
  }

  img.massvoll {
    width: 80px;
  }
`;

export const TextLink = styled('a')`
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin-top: 20px;

  &.at-bottom {
    padding: 5px;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

const popAnimation = keyframes`
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1.0);
  }
`;

export const Claim = styled('div')`
  position: absolute;
  text-transform: uppercase;
  font-weight: 900;
  position: absolute;
  text-transform: uppercase;
  font-weight: 900;
  transform: rotate(-7deg) translateY(50%);
  text-align: center;
  z-index: 10;
  bottom: 0;
  right: 0;
  transform: rotate(-7deg) translate(20%, 35%);
  font-size: 0.875rem;
  width: 250px;
  color: ${colors.yellow};
  font-family: 'kyrillasansserif', Arial, Helvetica, sans-serif;

  .claim-content {
    animation: ${popAnimation} cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.8s infinite;
  }

  @media (min-width: ${screens.sm}) {
    font-size: 1rem;
    width: 280px;
    transform: rotate(-7deg) translate(50%, 60%);
  }

  @media (min-width: ${screens.lg}) {
    transform: rotate(-7deg) translate(30%, -30%);
    font-size: 1.125rem;
    width: 310px;
  }

  @media (min-width: ${screens.xl}) {
    font-size: 1.2rem;
    transform: rotate(-7deg) translate(50%, -20%);
    width: 330px;
  }
`;
