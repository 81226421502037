import React, { Component } from 'react';
import equal from 'fast-deep-equal';
import Ship from '../GameObjects/Ship';
import InvadersController from '../Invaders/InvadersController';
import GameView from './GameView';
import GameUpdates from '../GameUpdates';
import { checkCollisionsWith } from '../../utils/collision';
import { shipControlSchemes, getShipActions } from '../../utils/shipActions';
import objectSizes from './objectSizes';

const FPS = 30;
const SHIP_INVADER_COLLISION_TOLERANCE = 1;

const mergeRemoteUpdates = (updates) => {
  let data = {
    merged: true
  };

  updates.forEach((update) => {
    Object.keys(update).forEach((key) => {
      if (key === 'timestamp') return;

      if (key === 'ships' && data[key]) {
        data[key] = { ...data[key], ...update[key] };
      } else {
        data[key] = update[key];
      }
    });
  });

  return data;
};

class Game extends Component {
  constructor(props) {
    super(props);

    this.state = {
      context: null
    };

    this.invadersController = null;

    this.ships = {
      1: null,
      2: null
    };

    this.canvas = React.createRef();
    this.animationLoop = null;
    this.currentLevel = null;
    this.isPaused = false;

    // Remote updates
    this.updatesQueue = [];
    this.prevGameUpdateTs = 0;

    this.prevUpdateTime = 0;
    this.prevInvadersMovedDownCount = 0;
  }

  componentDidMount() {
    const context = this.canvas.current.getContext('2d');
    this.setState({ context: context });

    if (this.props.isPlaying) {
      this.startGame();
    }

    if (this.props.livesCount) {
      this.checkLivesCount();
    }

    // If the game has already started, the server sends an update on mount
    if (this.context.update != null) {
      this.updatesQueue.push(this.context.update);
      this.prevGameUpdateTs = this.context.update.timestamp;
    }
  }

  componentWillUnmount() {
    this.stopAnimationLoop();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isPlaying && this.props.isPlaying) {
      this.startGame();
    }

    if (this.props.livesCount && !equal(this.props.livesCount, prevProps.livesCount)) {
      this.checkLivesCount();
    }

    if (!this.props.isRemote) return;

    // Ignore updates while the game is paused
    // Check update's timestamp to distinguish between updates
    if (
      this.context.update != null &&
      !this.prevGameUpdateTs !== this.context.update.timestamp &&
      !this.isPaused
    ) {
      const { update } = this.context;

      this.updatesQueue.push(update);
      this.prevGameUpdateTs = update.timestamp;

      if (this.updatesQueue.length > 2) {
        this.updatesQueue = [mergeRemoteUpdates(this.updatesQueue)];
      }
    }
  }

  checkLivesCount() {
    let totalLivesCount = 0;

    Object.keys(this.props.livesCount).forEach((shipName) => {
      if (this.props.livesCount[shipName] === 0) {
        this.ships[shipName] = null;
      } else {
        totalLivesCount += this.props.livesCount[shipName];
      }
    });

    // If both ships don't have lives left
    if (totalLivesCount === 0) {
      this.endGame();
    }
  }

  startGame() {
    this.currentLevel = this.props.initialLevel;

    this.createShips(this.props.shipsCount);

    this.invadersController = new InvadersController({
      sprites: this.props.sprites.invaders,
      deathSprite: this.props.sprites.explosion,
      onInvaderDeath: this.onInvaderDeath,
      screen: this.props.screen,
      size: objectSizes.invaders.default,
      selfInitiatedFire: !this.props.isRemote,
      fps: FPS,
      bulletSprites: {
        normal: this.props.sprites.bulletEnemyNormal,
        fast: this.props.sprites.bulletEnemyFast,
        boss: this.props.sprites.bulletBoss
      },
      topOffset: 0,
      fireRate: this.props.shipsCount > 1 ? 4500000 : 7000000
    });
    this.invadersController.createLevel(this.currentLevel);

    this.startAnimationLoop();
  }

  startAnimationLoop() {
    let then = performance.now();
    let prev = then;
    const frameDuration = 1000 / FPS;

    const animateLoop = (now) => {
      this.animationLoop = requestAnimationFrame(animateLoop);

      const delta = now - then;

      if (delta >= frameDuration) {
        // Update time
        // now - (delta % interval) is an improvement over just
        // using then = now, which can end up lowering overall fps
        then = now - (delta % frameDuration);

        // Calculate delta time
        let dt = Math.round(now - prev) / 100;
        prev = now;

        // Cap delta time
        dt = dt > 1 ? 1 : dt;

        this.update(dt, now);
      }
    };
    this.animationLoop = requestAnimationFrame(animateLoop);
  }

  stopAnimationLoop() {
    cancelAnimationFrame(this.animationLoop);
  }

  endGame() {
    this.ships[1] = null;
    this.ships[2] = null;
    if (this.invadersController) {
      this.invadersController.destroy();
    }
    this.stopAnimationLoop();

    this.props.onGameOver && this.props.onGameOver();
  }

  getShipYOffset() {
    const flameSize = objectSizes.flame;

    return (
      this.props.screen.height - objectSizes.ship.height - 10 - flameSize.height - flameSize.gutter
    );
  }

  getInitialShipsConfig(shipsCount) {
    const shipSize = objectSizes.ship;
    const y = this.getShipYOffset();
    const centerX = this.props.screen.width / 2;
    const xs = {
      left: centerX - shipSize.width / 2,
      right: centerX + shipSize.width / 2,
      center: centerX
    };

    const baseConfig = {
      size: shipSize,
      sprite: this.props.sprites.ship,
      flame: {
        size: objectSizes.flame,
        sprite: this.props.sprites.flame
      },
      bulletSprite: this.props.sprites.bulletShip
    };

    if (shipsCount === 1) {
      return [
        {
          position: {
            x: xs.center,
            y
          },
          shipName: 1,
          ...baseConfig
        }
      ];
    }

    let config = [
      {
        position: {
          x: xs.left,
          y
        },
        shipName: 1,
        ...baseConfig
      },
      {
        position: {
          x: xs.right,
          y
        },
        shipName: 2,
        ...baseConfig,
        sprite: this.props.sprites.shipAlt
      }
    ];

    if (this.props.playerId === 2) {
      config = [...config].reverse();
    }

    return config;
  }

  createShips(shipsCount) {
    const shipsConfig = this.getInitialShipsConfig(shipsCount);
    const { isRemote, spectatorMode } = this.props;

    let controlSchemes = [
      shipsCount === 1 || isRemote ? shipControlSchemes.ANY : shipControlSchemes.WASD,
      isRemote ? shipControlSchemes.REMOTE : shipControlSchemes.ARROWS
    ];

    // The first ship is always player-controlled
    this.ships[shipsConfig[0].shipName] = new Ship({
      onDie: spectatorMode ? undefined : this.onShipHit,
      controlScheme: spectatorMode ? shipControlSchemes.REMOTE : controlSchemes[0],
      ...shipsConfig[0],
      animateSizeOnInit: isRemote && !spectatorMode,
      isRemote: spectatorMode
    });

    // The second ship is either player-controlled (in 2P local mode) or remote-controlled
    if (shipsCount > 1) {
      this.ships[shipsConfig[1].shipName] = new Ship({
        onDie: isRemote ? undefined : this.onShipHit,
        controlScheme: spectatorMode ? shipControlSchemes.REMOTE : controlSchemes[1],
        isRemote,
        ...shipsConfig[1]
      });
    }
  }

  getOwnShip() {
    return this.ships[this.props.playerId];
  }

  checkInvadersReachedShips() {
    const { maxPositionY } = this.invadersController;
    if (maxPositionY < 1) return;

    const shipTopY = this.getShipYOffset();

    if (maxPositionY > shipTopY - SHIP_INVADER_COLLISION_TOLERANCE) {
      this.ships[1] = 0;
      this.ships[2] = 0;

      this.props.onLivesCountChange({
        1: 0,
        2: 0
      });
    }
  }

  onShipHit = (shipName) => {
    if (!this.props.enableShipDeath) return;

    const { livesCount } = this.props;

    // Update lives count
    const nextLivesCount = {
      ...this.props.livesCount,
      [shipName]: livesCount[shipName] - 1
    };

    this.props.onLivesCountChange(nextLivesCount, shipName);

    if (nextLivesCount[shipName] === 0) {
      this.ships[shipName] = null;
    }
  };

  onInvaderDeath = (obj, scoreValue) => {
    // In a remote game the score is counted by the server, so there's nothing to do locally
    if (this.props.isRemote) {
      return;
    }

    // If an invader died due to a ship's bullet, increase the ship's score
    if (obj && obj.firedBy && this.props.onLocalScoreChange) {
      this.props.onLocalScoreChange(obj.firedBy, scoreValue);
    }
  };

  getAliveShips() {
    let ships = [];
    if (this.ships[1] !== null) {
      ships = [this.ships[1]];
    }
    if (this.ships[2] !== null) {
      ships = [...ships, this.ships[2]];
    }
    return ships;
  }

  getActiveShipBullets() {
    let bullets = [];
    if (this.ships[1] !== null) {
      bullets = this.ships[1].bullets;
    }
    if (this.ships[2] !== null) {
      bullets = [...bullets, ...this.ships[2].bullets];
    }
    return bullets;
  }

  resetLevelState() {
    this.getAliveShips().forEach((ship) => {
      ship.bullets = [];
    });

    this.invadersController.resetState();
  }

  onLevelComplete() {
    if (this.currentLevel === 0) {
      this.isPaused = true;
      this.resetLevelState();
      this.currentLevel++;
      this.onLevelUpdate();

      this.props.onLevelComplete().then(() => {
        this.isPaused = false;
        this.invadersController.createLevel(this.currentLevel);
      });
    } else {
      this.endGame();
    }
  }

  renderBackground() {
    const ctx = this.state.context;
    ctx.save();
    ctx.scale(this.props.screen.pixelRatio, this.props.screen.pixelRatio);
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.clearRect(0, 0, this.props.screen.width, this.props.screen.height);
    ctx.globalAlpha = 1;
  }

  getRemoteUpdate() {
    if (this.updatesQueue.length === 0) {
      return null;
    }

    return this.updatesQueue.shift();
  }

  applyRemoteUpdate() {
    let queuedUpdate = this.getRemoteUpdate();
    if (queuedUpdate === null) return;

    // Apply a remote update to invaders if needed
    if (queuedUpdate.invaders) {
      const { movedDownCount, ...restInvadersState } = queuedUpdate.invaders;
      this.invadersController.updateInvadersState(restInvadersState, queuedUpdate.origin);
      this.invadersController.updateMovedDownCount(movedDownCount);
    }

    // Fire bullets in case they were issued by the server
    if (queuedUpdate.invaderBullets) {
      this.invadersController.fireBullets(queuedUpdate.invaderBullets);
    }

    if (queuedUpdate.ships) {
      this.getAliveShips().forEach((ship) => {
        // Apply remote updates to the current ship
        if (queuedUpdate.ships[ship.shipName]) {
          const shipUpdate = queuedUpdate.ships[ship.shipName];
          ship.applyUpdate(shipUpdate);
        }
      });
    }
  }

  update(dt, now) {
    if (this.isPaused || !this.props.isPlaying) return;

    this.applyRemoteUpdate();

    this.renderBackground();

    const preInvadersCount = this.invadersController.getAliveCount();

    // Every invader has been killed
    if (preInvadersCount === 0) {
      this.onLevelComplete();
    }

    const aliveShips = this.getAliveShips();

    // Check for collisions
    this.checkCollisions(aliveShips);

    // Update ships
    this.updateShips(aliveShips, dt);

    // Update invaders
    this.invadersController.update(dt);
    this.invadersController.render({ ...this.state, screen: this.props.screen }, dt);

    // Send a remote update if needed
    this.onClientUpdate(now, preInvadersCount);

    this.state.context.restore();
  }

  checkCollisions(aliveShips) {
    const invaders = this.invadersController.get();

    // Check collisions of bullets fired by ships with invaders
    checkCollisionsWith(this.getActiveShipBullets(), invaders);

    // Check collisions of ships with invaders
    this.checkInvadersReachedShips();

    // Check collisions of bullets fired by invaders with ships
    checkCollisionsWith(this.invadersController.bullets, aliveShips);
  }

  updateShips(ships, dt) {
    const now = Date.now();
    const renderState = { ...this.state, screen: this.props.screen };

    ships.forEach((ship) => {
      if (!ship.isRemote) {
        const shipActions = getShipActions(
          this.props.pressedKeys,
          this.props.pressedButtons,
          ship.controlScheme
        );
        ship.updateActions(shipActions);
      }

      ship.update(dt, now);
      ship.render(renderState, dt);
    });
  }

  onLevelUpdate() {
    if (!this.props.isRemote || !this.props.onUpdate || this.props.spectatorMode) return;

    this.props.onUpdate({
      lvl: this.currentLevel
    });
  }

  onClientUpdate(now, preInvadersCount) {
    if (!this.props.isRemote || !this.props.onUpdate || this.props.spectatorMode) return;

    let localUpdate = {
      lvl: this.currentLevel
    };
    const ownShip = this.getOwnShip();

    if (ownShip) {
      const shipState = ownShip.serialize();
      if (shipState) {
        localUpdate.ships = {
          [ownShip.shipName]: shipState
        };
      }
    }
    const invadersKilled = preInvadersCount - this.invadersController.getAliveCount();
    const includeInvaderPos = this.props.playerId === 1;
    const invadersMovedDownCount = this.invadersController.movedDownCount;

    if (
      invadersMovedDownCount > this.prevInvadersMovedDownCount ||
      invadersKilled > 0 ||
      (includeInvaderPos && now - this.prevUpdateTime > 100)
    ) {
      const invadersState = this.invadersController.serialize(includeInvaderPos);
      this.prevUpdateTime = now;
      localUpdate.invaders = {
        list: invadersState,
        timestamp: Date.now(),
        movedDownCount: invadersMovedDownCount
      };
    }

    this.prevInvadersMovedDownCount = invadersMovedDownCount;

    if (localUpdate.invaders || localUpdate.ships) {
      this.props.onUpdate(localUpdate);
    }
  }

  render() {
    return <GameView screen={this.props.screen} innerRef={this.canvas} />;
  }
}

Game.defaultProps = {
  shipsCount: 1,
  onUpdate: () => {}
};

Game.contextType = GameUpdates;

export default Game;
