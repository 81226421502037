import styled from 'styled-components/macro';
import { screens, colors, uiSizing } from '../../styles/theme';
import WaveImg from '../../assets/images/wave-single.svg';
import WaveTransparentImg from '../../assets/images/wave-single-transparent.svg';
import { topOffset } from './gameConfig';
import { waveAnimation } from '../../styles/shared-styles';

export const GameScreenWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  /* Top offset to account for game stats */
  padding-top: ${topOffset.mobile}px;
  background-color: #28328a;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #28328a;
    background-image: url(${WaveImg});
    background-repeat: repeat;
    background-size: ${(props) => props.waveBgWidth || 150}%;
    background-position: center top;
    z-index: 1;
    width: 100%;
    height: 200%;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
  }

  &.animated:before {
    animation: ${waveAnimation} linear 5s infinite;
  }

  @media (min-width: ${screens.sm}) {
    background-color: #060d3c;
    &:before {
      background-color: #060d3c;
      background-image: url(${WaveTransparentImg});
    }

    /* Top offset to account for game stats */
    padding-top: ${topOffset.desktop}px;
  }

  canvas {
    margin: 0 auto;
    display: block;
    z-index: 1;
    position: relative;
  }
`;

export const GameContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: ${colors.red};

  .game-screen-wrapper {
    display: none;
  }

  &.isPlaying .game-screen-wrapper {
    display: block;
  }

  .game-column {
    display: none;
    flex: 1 1 0;
    width: 0;
  }

  @media (min-width: ${screens.xs}) {
    background-color: ${colors.blue};
  }

  @media (min-width: ${screens.sm}) {
    background-color: transparent;
  }

  &.screen-desktopHorizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: normal;

    /* display the columns */
    .game-column {
      display: flex;
    }
  }
`;

export const PlaceholderColumn = styled('div')`
  max-width: ${uiSizing.gameColumnWidth}px;
  margin: 0 auto;
`;
