const environment = process.env.NODE_ENV || 'development';
const isProduction = environment === 'production';
const isDev = !isProduction;
const apiEndpoint = isProduction ? 'https://invaders.games' : 'http://localhost:3001';

const config = {
  apiEndpoint,
  environment,
  isProduction,
  isDev,
};

export default config;
