import React, { useEffect, useState } from 'react';
import { useInterval } from '../../hooks/useInterval';
import * as S from './styles';

function GameIntro({ style, onIntroEnded }) {
  const [count, setCount] = useState(5);

  useEffect(() => {
    const endTimer = setTimeout(onIntroEnded, 5000);

    return () => clearTimeout(endTimer);
  }, [onIntroEnded]);

  useInterval(() => {
    let nextCount = count - 1 > 0 ? count - 1 : 0;
    setCount(nextCount);
  }, 1000);

  return (
    <S.IntroWrapper style={style}>
      <p>Milk Phasers Engaged!</p>
      <p className="fade-in">
        Defeat the Fusel Invaders
        <br />
        and clean Planet Parliament’s Vodka!
      </p>
      <p className="fade-in countdown">{count}</p>
    </S.IntroWrapper>
  );
}

GameIntro.defaultProps = {
  onIntroEnded: () => {},
};

export default GameIntro;
