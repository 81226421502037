export const colors = {
  blue: '#050a31',
  red: '#df1e3c',
  lightRed: '#ff6f84',
  darkRed: '#b1001a',
  yellow: '#ffd400'
};

const breakpoints = ['375px', '600px', '768px', '992px', '1200px'];

export const screens = {
  xs: breakpoints[0],
  sm: breakpoints[1],
  md: breakpoints[2],
  lg: breakpoints[3],
  xl: breakpoints[4]
};

export const uiSizing = {
  mobileTouchControlsHeight: 100,
  tabletTouchControlsHeight: 150,
  tabletTouchControlsWidth: 110,
  gameHeaderHeight: 150,
  gameColumnWidth: 280,
};