import GameObject from './GameObject';
import objectSizes from '../Game/objectSizes';

const defaultSize = objectSizes.bullets.default;
const DEFAULT_SPEED = 2;

const getInitialY = (y, direction, size) => {
  if (direction === 'down') {
    return y;
  }
  return y - size.height;
};

class Bullet extends GameObject {
  constructor(props) {
    const sprite = props.getSprite(props.spriteName);
    const bulletSize = sprite.size || defaultSize;

    super({
      position: {
        x: props.position.x - bulletSize.width / 2,
        y: getInitialY(props.position.y, props.direction, bulletSize)
      },
      speed: props.speed || DEFAULT_SPEED,
      size: bulletSize
    });
    this.direction = props.direction;
    this.firedBy = props.firedBy;
    this.isRemote = props.isRemote;
    this.spriteName = props.spriteName;
    this.getSprite = props.getSprite;
    this.createdAt = Date.now();
  }

  update() {
    if (this.direction === 'up') {
      this.position.y -= this.speed;
    } else {
      this.position.y += this.speed;
    }
  }

  render(state) {
    if (this.position.y > state.screen.height || this.position.y < 0) {
      this.die();
    }

    const context = state.context;
    context.save();
    context.translate(this.position.x, this.position.y);
    context.drawImage(this.getSprite(this.spriteName).src, 0, 0, this.size.width, this.size.height);
    context.restore();
  }
}

export default Bullet;
