import React from 'react';
import InputListener from '../InputListener';
import * as S from './styles';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import Logo from '../../assets/images/logo.png';

function GameStartScreen({ playerReady, onGameStartRequest, style, spectatorMode }) {
  const waitMessage = spectatorMode
    ? 'Waiting for the game to start...'
    : 'Waiting for another player...';

  return (
    <S.StartScreenWrapper style={style}>
      <InputListener onFireButton={onGameStartRequest} />
      <S.Logo>
        <img src={Logo} alt="" />
      </S.Logo>

      <S.CenteredText>
        {playerReady || spectatorMode ? (
          waitMessage
        ) : (
          <S.StartButton onClick={onGameStartRequest}>
            <StarIcon />
            <span>Press Fire to Start</span>
            <StarIcon />
          </S.StartButton>
        )}
      </S.CenteredText>

      <S.FooterText>COPYRIGHT PARLIAMENT VODKA - 2019</S.FooterText>
    </S.StartScreenWrapper>
  );
}

export default GameStartScreen;
