import styled from 'styled-components/macro';
import { colors, screens } from '../../styles/theme';

export const UIWrapper = styled('div')`
  background-color: ${colors.red};
  user-select: none;

  &.mobileMode {
    position: relative;
    padding: 7px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    /* screens.xs - 1 */
    @media (max-width: 374px) {
      .ui-optional {
        display: none;
      }
    }

    @media (min-width: ${screens.xs}) {
      padding: 25px 20px 7px;
    }

    @media (min-width: ${screens.sm}) {
      width: 100%;
    }
  }

  &.tabletMode {
    .ui-part {
      display: none;
    }
    .game-screen {
      padding: 0;
    }

    &.horizontal {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-grow: 1;
    }

    &.vertical {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
`;

export const BottomUI = styled('div')`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  .parliament-logo {
    display: none;
    position: absolute;
    bottom: 5px;
    left: calc(50% - 32px);
    width: 64px;
  }

  @media (min-width: ${screens.xs}) {
    margin: 0 0 25px;

    .parliament-logo {
      display: block;
    }
  }
`;

export const FakeUI = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 7px 5px 14px;

  .ui-grill {
    height: 17px;
  }

  svg {
    margin: 0 auto;
  }
`;

export const GameScreen = styled('div')`
  position: relative;
  padding-bottom: 7px;

  .power-indicator {
    position: absolute;
    top: 0px;
    right: -25px;
  }
`;

const barHeight = '7px';

export const HorizontalBar = styled('div')`
  position: absolute;
  left: 0;
  width: 100%;
  height: ${barHeight};

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 0;
  }

  &::before {
    border-width: 0 0 ${barHeight} ${barHeight};
    left: -${barHeight};
  }

  &::after {
    border-width: 0 ${barHeight} ${barHeight} 0;
    right: -${barHeight};
  }

  &.above-top {
    top: -${barHeight};
  }

  &.below-bottom {
    bottom: -${barHeight};
  }

  &.at-top {
    top: 0;
  }

  &.at-bottom {
    bottom: 0;
  }

  &.upside-down {
    transform: rotate(180deg);
  }

  &.inner {
    width: calc(100% - ${barHeight}*2);
    left: ${barHeight};
  }

  &.light-red {
    background-color: ${colors.lightRed};
    &::before {
      border-color: transparent transparent ${colors.lightRed} transparent;
    }
    &::after {
      border-color: transparent transparent ${colors.lightRed} transparent;
    }
  }

  &.dark-red {
    background-color: ${colors.darkRed};
    &::before {
      border-color: transparent transparent ${colors.darkRed} transparent;
    }
    &::after {
      border-color: transparent transparent ${colors.darkRed} transparent;
    }
  }
`;
