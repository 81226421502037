import React from 'react';
import cc from 'classcat';
import LivesCount from '../LivesCount/LivesCount';
import ControlScheme from '../ControlScheme/ControlScheme';
import TextControlScheme from '../TextControlScheme/TextControlScheme';
import { padNumber } from '../../utils/pad';
import * as S from './styles';
import Logo from '../../assets/images/logo.png';
import SpacemanLeft from '../../assets/images/spaceman-left.png';
import SpacemanRight from '../../assets/images/spaceman-right.png';

function ControlsColumn({ score, playerNumber, livesCount, controlScheme, displayPlayerScore }) {  
  return (
    <S.Wrapper className="game-column">
      <S.Logo>
        <img src={Logo} alt="" />
      </S.Logo>
      {displayPlayerScore && (
        <S.PlayerStats>
          <S.TextBlock>
            <span>P{playerNumber} Score: </span>
            <span className="color-red">{padNumber(score, 7)}</span>
          </S.TextBlock>
          <S.PlayerLives>
            <S.TextBlock className="lives-label">{livesCount > 0 ? 'LIVES: ' : 'DEAD'}</S.TextBlock>
            <LivesCount count={livesCount} />
          </S.PlayerLives>
        </S.PlayerStats>
      )}
      <img
        className={cc(['spaceman', { 'always-visible': !displayPlayerScore }])}
        src={playerNumber === 1 ? SpacemanLeft : SpacemanRight}
        alt="Spaceman"
      />
      {controlScheme === 'any' ? <TextControlScheme /> : <ControlScheme type={controlScheme} />}
    </S.Wrapper>
  );
}

export default React.memo(ControlsColumn);
