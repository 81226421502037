import styled from 'styled-components/macro';
import { screens, colors } from '../../styles/theme';
import { BaseButton } from '../../styles/shared-styles';

export const HighscoreWrapper = styled('div')`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.3px;
  color: #fff;
  background-color: ${colors.blue};
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;

  .logo {
    max-width: 102px;
    width: 100%;
    margin: 1rem auto;
    display: block;
    cursor: pointer;
  }

  .small {
    font-size: 10px;
  }

  @media (min-width: ${screens.sm}) {
    flex-grow: 0;
    height: 100vh;

    &.ingame {
      height: auto;

      .logo {
        display: none;
      }
    }
  }

  @media (min-width: ${screens.lg}) {
    font-size: 24px;

    .small {
      font-size: 14px;
    }
  }
`;

export const Table = styled('div')`
  padding: 0 25px;
  margin: 1rem auto;
  max-width: 450px;

  @media (min-width: ${screens.xs}) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: ${screens.sm}) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

export const TableRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TableColumn = styled('div')`
  &.position {
    width: 20%;
    min-width: 30px;
    text-align: right;
  }

  &.player-name {
    width: 40%;
  }

  &.player-score {
    width: 40%;
    text-align: right;
  }

  & + & {
    padding-left: 25px;
  }
`;

export const OverlayWrapper = styled('div')`
  padding: 4rem 0 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;

  .ingame & {
    padding: 4rem 0 15vh;
  }

  @media (min-width: ${screens.sm}) {
    position: absolute;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(10, 16, 62, 0) 0%, #0a103e 100%);
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #0a103e;
    z-index: 1;
  }
`;

export const OverlayContent = styled('div')`
  text-align: center;
  position: relative;
  z-index: 2;
`;

export const ScoreForm = styled('form')`
  .current-player-score {
    color: ${colors.red};
    margin-bottom: 15px;
  }

  label {
    display: block;
    margin-bottom: 3px;
  }

  .field-wrapper {
    width: 160px;
    margin: 0 auto;

    &:after {
      content: '';
      display: block;
      height: 8px;
      border-bottom: 8px solid #fff;
    }
  }

  input {
    outline: 0;
    border: 0;
    border-radius: 0;
    appearance: none;
    text-transform: uppercase;
    font-weight: bolder;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 50px;
    letter-spacing: 5px;
    color: ${colors.red};
    background: 0;
    text-align: center;
    padding: 0 0 0 5px;
    height: 1.1em;
    width: 100%;

    ::placeholder {
      opacity: 0.7;
      color: ${colors.red};
    }
  }

  @media (min-width: ${screens.xs}) {
    .field-wrapper {
      width: 220px;
      margin: 0 auto;
      &:after {
        border-bottom-width: 10px;
      }
    }
    input {
      font-size: 70px;
    }
  }
`;

export const Button = styled(BaseButton)`
  color: #fff;
  font-weight: bold;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 32px;
  margin-top: 20px;

  &.smaller {
    font-size: 24px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Tabs = styled('nav')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 2.5rem 0 2.75rem;
  font-size: 24px;
`;

export const Tab = styled(BaseButton)`
  color: ${colors.red};
  font-weight: bolder;

  & + & {
    margin-left: 2rem;
  }

  &.active {
    border-bottom: 3px solid ${colors.red};
  }
`;
