import React from 'react';
import Logo from '../../assets/images/logo.png';
import * as S from './styles';
import TextControlScheme from '../TextControlScheme/TextControlScheme';

function GameHeader({ maxWidth, controlScheme, history }) {
  const onLogoClick = (event) => {
    event.preventDefault();

    if (window.confirm('Do you really want to leave this page?')) {
      history.push('/');
    }
  };

  return (
    <S.GameHeader style={{ maxWidth }}>
      <S.Logo onClick={onLogoClick}>
        <img src={Logo} alt="" />
      </S.Logo>
      <TextControlScheme type={controlScheme} isHorizontal={true} />
    </S.GameHeader>
  );
}

export default React.memo(GameHeader);
