import styled, { css, keyframes } from 'styled-components/macro';
import { colors, screens } from './theme';
import PatternImg from '../assets/images/pattern.png';
import WaveImg from '../assets/images/wave-single.svg';

const blueGradient = 'linear-gradient(-180deg, #010312 0%, #050A31 50%, #1D2574 100%)';

export const waveAnimation = keyframes`
  0% {
    transform: translateY(0) translateZ(0);
  }
  50% {
    transform: translateY(25%) translateZ(0);
  }
  100% {
    transform: translateY(50%) translateZ(0);
  }
`;

export const flexCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BaseGameScreenWrapper = styled('div')`
  width: 100%;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #31409a;
    background-image: url(${WaveImg});
    background-repeat: repeat;
    background-size: ${(props) => props.waveBgWidth || 150}%;
    background-position: center top;
    z-index: -1;
    width: 100%;
    height: 200%;
  }
  
  @media (min-width: ${screens.sm}) {
    &:before {
      background-image: none;
      background-color: ${colors.blue};
    }
  }
`;

export const BaseButton = styled('button')`
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  appearance: none;
`;

export const BaseTextBlock = styled('div')`
  color: #fff;
  letter-spacing: 0.5px;
  text-align: center;

  span.color-red {
    color: ${colors.red};
  }
`;

export const BackgroundWrapper = styled('div')`
  background-image: ${blueGradient};
  background-image: url(${PatternImg}), ${blueGradient};
  background-repeat: repeat;
  background-size: 150%;
  min-height: ${(props) => props.minHeight ? `${props.minHeight}px` : '100vh'};
  display: flex;
  flex-direction: column;

  &.relative {
    position: relative;
  }

  &.overflow-hidden {
    overflow: hidden;
  }
`;

export const LogoImage = styled('img')`
  max-width: 200px;

  @media (min-width: ${screens.sm}) {
    max-width: 250px;
  }
`;
