import styled from 'styled-components/macro';
import { colors, screens } from '../../styles/theme';
import { BaseButton, BackgroundWrapper } from '../../styles/shared-styles';

export const IntroWrapper = styled(BackgroundWrapper)`
  min-height: auto;
  
  &.inline {
    background: none;
  }

  .mobile-only {
    @media (min-width: ${screens.sm}) {
      display: none;
    }
  }

  .desktop-only {
    @media (max-width: 599px) {
      display: none;
    }
  }
`;

export const VideoWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 1rem;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
  }
`;

export const TextButton = styled(BaseButton)`
  color: ${colors.yellow};
  font-size: 0.875rem;
  letter-spacing: 0.1px;
`;

export const ButtonsList = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 2rem 2.5rem;

  @media (min-width: ${screens.sm}) {
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
`;
