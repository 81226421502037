import styled from 'styled-components/macro';
import { colors } from '../../styles/theme';
import { flexCenter } from '../../styles/shared-styles';
import ArrowTopIcon from '../../assets/icons/arrow-top.svg';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';

export const Wrapper = styled('div')`
  margin: 2rem 0 1.5rem;
`;

export const Key = styled('div')`
  ${flexCenter}
  background: ${colors.red};
  border-radius: 10px;
  font-size: 0.875rem;
  color: ${colors.darkRed};
  letter-spacing: 0.5px;
  text-align: center;
  width: 67px;
  height: 67px;
  position: relative;
  box-shadow: ${colors.darkRed} 0 6px 0;
  margin-bottom: 6px;
  text-transform: uppercase;

  &.wide {
    width: 100%;
  }

  &.disabled {
    background-color: #732a56;
    box-shadow: #5e1b44 0 6px 0;
    color: #5e1b44;
  }

  &.arrow::before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center;
  }

  &.arrow-top::before,
  &.arrow-down::before {
    background-image: url(${ArrowTopIcon});
  }

  &.arrow-left::before,
  &.arrow-right::before {
    background-image: url(${ArrowLeftIcon});
  }

  &.arrow-down::before,
  &.arrow-right::before {
    transform: rotate(180deg);
  }
`;

export const KeyLabel = styled('div')`
  font-size: 0.875rem;
  color: #fff;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  &.align-right {
    text-align: right;
    right: 21px;
  }

  &.align-center {
    text-align: center;
    left: 0;
    right: 0;
  }

  &.align-left {
    text-align: left;
    left: 0;
  }

  &.at-top,
  &.at-bottom {
    position: absolute;
    white-space: nowrap;
    width: 100%;
  }

  &.at-bottom {
    bottom: 0;
    transform: translateY(100%);
    padding-top: 5px;
  }

  &.at-top {
    top: 0;
    transform: translateY(-100%);
    padding-bottom: 5px;
  }
`;

export const KeyWrapper = styled('div')`
  position: relative;

  & ~ & {
    margin-left: 0.5rem;
  }

  &.wide {
    width: 100%;
  }
`;

export const KeysRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5rem;

  &.with-bottom-labels {
    margin-bottom: 2.5rem;
  }

  &.with-top-labels {
    margin-top: 4rem;
  }
`;
