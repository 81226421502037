import GameObject from '../GameObjects/GameObject';
import changeColor from '../../utils/changeColor';

export const Direction = {
  Left: 0,
  Right: 1
};

const INVADER_MIN_FIRERATE = 1000;
const INVADER_INIT_DELAY_FIRERATE = 10000;
const INVINCIBLE_FRAMES_COUNT = 90;
const SHIELD_OVERLAY_COLOR = 'hsla(175, 82%, 50%, .7)';

const isFirefox = window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

class Invader extends GameObject {
  constructor(props) {
    super({
      position: props.position,
      onDie: props.onDie,
      speed: props.speed,
      size: props.size
    });
    this.isInvader = true;
    this.onBulletFired = props.onBulletFired;
    this.direction = Direction.Right;
    this.spriteName = props.spriteName;
    this.getSprite = props.getSprite;
    this.bulletSpriteName = props.bulletSpriteName;
    this.fireRate = props.initialFireRate;
    this.id = props.id;
    this.name = props.name;
    this.rowId = props.rowId;
    this.selfInitiatedFire = props.selfInitiatedFire;
    this.downSpeedDelta = props.downSpeedDelta;
    this.nextFrameId = 0;
    this.killedBy = 0;
    this.livesCount = props.livesCount || 1;
    this.livesPerState = props.livesPerState || 1;
    this.scoreValue = props.scoreValue;

    // Invincibility
    this.barrierHeight = props.barrierHeight || 0;
    this.isInvincible = props.isInvincible === true;
    this.isInvinciblePermanently = props.isInvinciblePermanently === true;
    this.invincibleFramesLeft = 0;
    this.invincibleOnStateChange = props.invincibleOnStateChange;

    // Initial delay
    this.lastShot = Date.now() - INVADER_INIT_DELAY_FIRERATE;
    // Fire rate multiplier
    this.fireRateMult = props.fireRateMult || 1;

    // Dead state variables
    this.localDead = false;
    this.localDeadTs = 0;
    this.remoteDead = false;
    this.remoteDeadTs = 0;

    this.deathSprite = props.deathSprite;
    this.deathFramesLeft = 0;
    this.deathFramesCount = this.deathSprite.isSpriteSheet ? this.deathSprite.src.length : 1;
  }

  resetLocalDead() {
    this.localDead = false;
    this.localDeadTs = 0;
  }

  isConsideredDead() {
    return this.dead || this.localDead;
  }

  isDying() {
    return this.deathFramesLeft > 0;
  }

  die(props) {
    // Invincibility state
    if (this.isInvincible) {
      return;
    }

    // Lives count change
    if (this.livesCount > 1) {
      const prevLivesCount = this.livesCount;
      this.livesCount--;

      if (this.invincibleOnStateChange) {
        const prevState = Math.ceil(prevLivesCount / this.livesPerState);
        const nextState = Math.ceil(this.livesCount / this.livesPerState);

        if (prevState > nextState) {
          this.invincibleFramesLeft = INVINCIBLE_FRAMES_COUNT;
          this.isInvincible = true;
        }
      }

      return;
    }

    // Death
    this.deathFramesLeft = this.deathFramesCount;

    // If a remote bullet hits an invader, it's considered a local death
    // This is used to temporarily consider the invader dead, either until
    // this player receives an ack from another player about the invader
    // Or the timeout passes (and the invader is considered alive again)
    if (props && props.isRemote) {
      this.localDead = true;
      this.localDeadTs = Date.now();
      return;
    }

    this.dead = true;

    // Save the name of the ship that fired the deadly bullet
    if (props && props.firedBy) {
      this.killedBy = props.firedBy;
    }

    if (this.onDie) {
      this.onDie(props, this.scoreValue);
    }
  }

  setSpeed(speed) {
    this.speed = speed;
  }

  // increaseFireRate() {
  //   this.fireRate = Math.ceil(this.fireRate * 0.9);
  // }

  reverse() {
    if (this.direction === Direction.Right) {
      this.direction = Direction.Left;
    } else {
      this.direction = Direction.Right;
    }
  }

  move(dt = 1) {
    if (this.direction === Direction.Right) {
      this.position.x += this.speed * dt;
    } else {
      this.position.x -= this.speed * dt;
    }
  }

  moveDown(value) {
    this.position.y += value * this.downSpeedDelta;
  }

  fireBullet(props) {
    this.lastShot = Date.now();

    let bulletProps = {
      position: {
        x: this.position.x + this.size.width / 2,
        y: this.position.y + this.size.height
      },
      direction: 'down',
      fast: Math.random() > 0.7,
      ...props
    };

    if (this.bulletSpriteName) {
      bulletProps.spriteName = this.bulletSpriteName;
    }

    this.onBulletFired(bulletProps);
  }

  hasReachedBounds(screenWidth) {
    if (
      // Check if an invader has reached the bounds
      (this.position.x + this.size.width >= screenWidth && this.direction === Direction.Right) ||
      (this.position.x <= 0 && this.direction === Direction.Left)
    ) {
      return true;
    }
    return false;
  }

  update(dt) {
    this.move(dt);

    if (!this.selfInitiatedFire || this.isConsideredDead()) return;

    const now = Date.now();
    const nextShot =
      (Math.random() * this.fireRate) / this.fireRateMult +
      INVADER_MIN_FIRERATE / this.fireRateMult;

    if (now - this.lastShot > nextShot) {
      this.fireBullet();
    }
  }

  getSpriteUrl(sprite) {
    let currentSprite = sprite.src;

    if (sprite.partial) {
      let spriteId = Math.ceil(this.livesCount / this.livesPerState);
      currentSprite = sprite.src[spriteId.toString()];
    }

    if (sprite.isStatic) return currentSprite;
    const spriteUrl = currentSprite[this.nextFrameId];
    this.nextFrameId = this.nextFrameId === currentSprite.length - 1 ? 0 : this.nextFrameId + 1;

    if (this.isDying()) {
      const deathFrameId = this.deathFramesCount - this.deathFramesLeft;
      this.deathFramesLeft--;
      return this.deathSprite.src[deathFrameId];
    }

    return spriteUrl;
  }

  onInvicibilityFrame() {
    if (!this.isInvinciblePermanently) {
      if (this.invincibleFramesLeft > 0) {
        this.invincibleFramesLeft--;
      } else {
        this.isInvincible = false;
      }
    }
  }

  addShieldColor(spriteUrl) {
    return changeColor(spriteUrl, SHIELD_OVERLAY_COLOR);
  }

  renderBarrier(ctx, sprite) {
    const x = sprite.flipH ? -this.size.width : 0;
    const barrierSize = this.barrierHeight / 2;

    ctx.fillStyle = SHIELD_OVERLAY_COLOR;
    ctx.fillRect(x, this.size.height - barrierSize - 1, this.size.width, barrierSize);
  }

  render(state) {
    if (this.position.y > state.screen.height || this.position.y < 0) {
      console.log(`Invader ${this.id} died due to getting out of bounds`);
      this.die();
    }

    const context = state.context;
    context.save();
    context.translate(this.position.x, this.position.y);

    let { width, height } = this.size;
    height -= this.barrierHeight;

    // If death animation is rendered, choose the smallest fitting square size
    if (this.isDying()) {
      width = Math.min(width, height);
      height = Math.min(width, height);
    }

    // Center the rendered shape if needed
    let posX = (this.size.width - width) / 2;

    // Get the sprite object from the controller
    const sprite = this.getSprite(this.spriteName);

    // Flip horizontally
    if (sprite.flipH) {
      context.scale(-1, 1);
      posX = -width - posX;
    }

    let spriteUrl = this.getSpriteUrl(sprite);

    if (this.isInvincible) {
      this.onInvicibilityFrame();
      if (!isFirefox) {
        spriteUrl = this.addShieldColor(spriteUrl);
      }
    }

    context.drawImage(spriteUrl, posX, 0, width, height);

    if (this.isInvincible) {
      this.renderBarrier(context, sprite);
    }

    context.restore();
  }
}

export default Invader;
