import React from 'react';
import TouchGameUI from './TouchGameUI/TouchGameUI';

function GameUI({ children, mode, screen, onControlsChange, pressedButtons, passThrough }) {
  if (passThrough) {
    return children;
  }

  if (mode === 'mobile' || mode.indexOf('tablet') > -1) {
    return (
      <TouchGameUI mode={mode} onChange={onControlsChange} pressedButtons={pressedButtons}>
        {children}
      </TouchGameUI>
    );
  }

  return (
    <div style={{ width: screen.width * screen.scaleRatio }} className="game-ui">
      {children}
    </div>
  );
}

export default GameUI;
