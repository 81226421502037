import React from 'react';

const getWindowSize = () => ({
  height: window.innerHeight,
  width: window.innerWidth
});

class WindowSize extends React.PureComponent {
  constructor() {
    super();

    this.state = getWindowSize();
  }

  handleResize = () => {
    this.setState(getWindowSize(), () => {
      this.props.onChange && this.props.onChange(this.state);
    });
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    return null;
  }
}

export default WindowSize;
