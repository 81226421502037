export default function changeColor(img, color) {
  let c = document.createElement('canvas');
  let ctx = c.getContext('2d');
  c.width = img.width;
  c.height = img.height;

  // Draw the original image
  ctx.globalCompositeOperation = 'source-over';
  ctx.drawImage(img, 0, 0, c.width, c.height);

  // ctx.globalCompositeOperation = 'color-burn'
  // ctx.fillStyle = "hsl(0, 70%, 50%)";
  // ctx.fillRect(0, 0, c.width, c.height);

  // Recolor using the color blending mode
  ctx.globalCompositeOperation = 'color';
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, c.width, c.height);

  // Draw again to return the alpha channel
  ctx.globalCompositeOperation = "destination-in";
  ctx.drawImage(img, 0, 0, c.width, c.height);

  // Reset composite mode to default
  ctx.globalCompositeOperation = "source-over";

  return c;
}
