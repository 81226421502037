import styled from 'styled-components/macro';
import { screens } from '../../styles/theme';
import { BaseTextBlock } from '../../styles/shared-styles';

export const StatsWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 0.75rem 1rem;

  &.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &:not(.always-visible) {
    &,
    &.flex-row {
      @media (min-width: ${screens.xl}) {
        display: none;
      }
    }
  }
`;

export const StatsRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.flex-column {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > :first-child {
      margin-bottom: 0.25rem;
    }
  }

  @media (min-width: ${screens.sm}) {
    &.flex-column > :first-child {
      margin-bottom: 0.5rem;
    }
  }
`;

export const TextBlock = styled(BaseTextBlock)`
  font-size: 0.75rem;

  &.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  > .lives-label {
    margin-right: 0.5rem;
  }

  @media (min-width: ${screens.sm}) {
    font-size: 0.875rem;
  }
`;
