import { useEffect } from 'react';

function InputListener({ onFireButton }) {
  useEffect(() => {
    const onKeyDown = (event) => {
      const { keyCode } = event;

      // Space or either Shift is pressed
      if (keyCode === 16 || keyCode === 32) {
        onFireButton && onFireButton(event);
      }
    };
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onFireButton]);

  return null;
}

export default InputListener;
