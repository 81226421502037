import styled, { keyframes } from 'styled-components/macro';
import { screens } from '../../styles/theme';

const flameAnimation = keyframes`
  0% {
    transform: translateX(-50%) scale(0.7);
  }

  100% {
    transform: translateX(-50%) scale(1.1);
  }
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const VictoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .bottle-icon {
    height: 150px;
    display: block;
  }

  .shot-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    transform: translateX(27%);
    height: 20px;
  }

  .flame-icon {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
    width: 18px;
    animation: ${flameAnimation} 100ms linear infinite;
    transform-origin: top;
    margin-top: 3px;
  }

  .fade-group {
    opacity: 0;
    transition: opacity 700ms ease-in;

    &.immediate {
      transition: none;
    }

    &.visible {
      opacity: 1;
    }
  }

  .sparkle-icon {
    position: absolute;
    animation: ${spinAnimation} 2s linear infinite;
    width: 40px;
    z-index: 2;

    &.top-left {
      top: 20%;
      left: -50%;
    }

    &.center-right {
      top: 40%;
      right: -20px;
    }

    &.small {
      width: 30px;
    }
  }

  .victory-icon {
    position: absolute;
    bottom: 12%;
    height: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .bottle-shot-wrapper {
    position: relative;
  }

  @media (min-width: ${screens.md}) {
    .bottle-icon {
      height: 200px;
    }
    .shot-icon {
      height: 25px;
    }
    .victory-icon {
      height: 50px;
    }
    .flame-icon {
      height: 45px;
      width: 20px;
    }
    .sparkle-icon {
      width: 50px;
      &.center-right {
        top: 50%;
      }
      &.small {
        width: 35px;
      }
    }
  }

  @media (min-width: ${screens.xl}) {
    .bottle-icon {
      height: 300px;
    }
    .shot-icon {
      height: 40px;
    }
    .victory-icon {
      height: 55px;
    }
    .flame-icon {
      height: 55px;
      width: 25px;
    }
    .sparkle-icon {
      width: 65px;
      &.center-right {
        top: 55%;
      }
      &.small {
        width: 40px;
      }
    }
  }
`;
