import React from 'react';
import cc from 'classcat';
import { Link } from 'react-router-dom';
import * as S from './styles';
import { LogoImage, BackgroundWrapper } from '../../styles/shared-styles';
import { supportsTouch } from '../../utils/browser';
import { ReactComponent as OnePlayerIcon } from '../../assets/icons/1-player.svg';
import { ReactComponent as TwoPlayerIcon } from '../../assets/icons/2-player.svg';
import ShipIcon from '../../assets/icons/ship.png';
import Spaceman from '../../assets/images/spaceman-ship.png';
import SpacemanHorizontal from '../../assets/images/spaceman-ship-horizontal.png';
import Logo from '../../assets/images/logo.png';
import MassvollLogo from '../../assets/images/massvoll-geniessen.png';

const GameMenu = ({ history }) => {
  const onLinkClick = (event) => {
    event.preventDefault();

    const to = event.target.getAttribute('to');
    if (to) {
      history.push(to);
    }
  };

  return (
    <BackgroundWrapper className="relative overflow-hidden">
      <S.Wrapper>
        <S.LeftCol>
          <img src={Spaceman} alt="" />
        </S.LeftCol>
        <S.RightCol>
          <div className="p-relative">
            <LogoImage className="logo" src={Logo} />
            <S.Claim>
              <div className="claim-content">
                Defeat the Fusel Invaders
                <br />
                with your Milk Phasers
                <br />
                And clean Planet Parliament’s Vodka!
              </div>
            </S.Claim>
          </div>
          <img className="spaceman-mobile" src={SpacemanHorizontal} alt="" />
          <S.GameModes>
            <S.ModeButton className="mode-1p" to="/localgame/1" onClick={onLinkClick} type="button">
              <OnePlayerIcon className="icon" />
              <span>1P</span>
            </S.ModeButton>
            <S.ModeButton
              className={cc(['mode-2p', { hidden: supportsTouch() }])}
              to="/localgame/2"
              onClick={onLinkClick}
              type="button"
            >
              <TwoPlayerIcon className="icon" />
              <span>2P</span>
            </S.ModeButton>
            <S.ModeButton
              className="mode-host"
              disabled
              type="button"
              style={{
                opacity: 0.5,
                cursor: 'default',
              }}
            >
              <img className="ship-icon icon" src={ShipIcon} alt="" />
              <span>Host</span>
            </S.ModeButton>
          </S.GameModes>
          <S.TextLink
            style={{
              opacity: 0.5,
              cursor: 'default',
            }}
          >
            Highscores
          </S.TextLink>
        </S.RightCol>
      </S.Wrapper>
      <S.Footer>
        <a
          className="at-left"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.massvoll-geniessen.de/"
        >
          <img className="massvoll" src={MassvollLogo} alt="Massvoll Geniessen" />
        </a>
        <S.TextLink
          className="at-right"
          style={{
            opacity: 0.5,
            cursor: 'default',
          }}
        >
          Convention Mode
        </S.TextLink>
        <S.TextLink
          rel="noopener noreferrer"
          target="_blank"
          href="http://parliament-vodka.de/#impressum"
        >
          Imprint
        </S.TextLink>
      </S.Footer>
    </BackgroundWrapper>
  );
};

export default GameMenu;
