import config from '../../config';

export const GameState = {
  Cutscene: 0,
  Init: 1,
  Intro: 2,
  Playing: 3,
  GameOver: 4,
  Highscore: 5
};

export const GameServerState = {
  Init: 0,
  GameReady: 1,
  PlayersReady: 2,
  InProgress: 3,
  Over: 4
};

export const topOffset = {
  mobile: 60,
  desktop: 70
};

export const internalScreenSize = {
  width: 350,
  height: 400
};

export const gameSettings = {
  animateBackground: config.isDev ? false : true,
  initialLevel: config.isDev ? 0 : 0,
  initialLivesCount: 3,
  initialScore: {
    1: 0,
    2: 0
  },
  initialPressedButtons: {
    left: false,
    right: false,
    fire: false
  },
  initialPlayerReady: config.isDev ? true : false,
  initialGameState: config.isDev ? GameState.Init : GameState.Cutscene,
  enableShipDeath: !config.isDev
};
