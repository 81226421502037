import objectSizes from '../Game/objectSizes';

export const invaderProps = {
  3: {
    fireRateMult: 4
  },
  11: {
    fireRateMult: 4
  },
  boss: {
    fireRateMult: 500,
    size: {
      width: objectSizes.invaders.boss.width,
      height: objectSizes.invaders.boss.height + 6 // size + barrier
    },
    barrierHeight: 6,
    livesCount: 15,
    livesPerState: 3,
    bulletSpriteName: 'boss',
    scoreValue: 5000,
    invincibleOnStateChange: true,
    // Boss is initially invincible
    isInvincible: true,
    isInvinciblePermanently: true
  },
  guard: {
    fireRateMult: 50,
    size: objectSizes.invaders.guard,
    livesCount: 2,
    scoreValue: 2000
  },
  guardFast: {
    fireRateMult: 50,
    size: objectSizes.invaders.guard,
    livesCount: 2,
    scoreValue: 2000,
    spriteName: 'guard',
    downSpeedDelta: 8
  }
};

// 0 is the placeholder invader

export const invaderPositions = {
  0: [
    {
      sprites: [0, 51, 0, 0, 52, 0]
    },
    {
      sprites: [8, 6, 2, 8, 6, 2]
    },
    {
      sprites: [9, 7, 9, 7, 9, 7, 9, 7]
    },
    {
      sprites: [3, 11, 3, 11, 3, 11]
    },
    {
      sprites: [1, 4, 1, 4, 1, 4, 1, 4]
    },
    {
      sprites: [9, 7, 9, 7, 9, 7, 9, 7],
      invaderProps: {
        downSpeedDelta: 3,
        livesCount: 2,
        scoreValue: 1000
      }
    }
  ],
  1: [
    {
      sprites: ['boss'],
      centered: true,
      noGutterX: true,
      gutterY: 3
    },
    {
      sprites: ['guard', 'guardFast', 'guardFast', 'guard'],
      centered: true,
      noGutterX: true
    },
    {
      sprites: ['guard', 'guardFast', 'guardFast', 'guard'],
      centered: true,
      noGutterX: true
    }
  ]
};
