export function checkCollisionsWith(groupA, groupB) {
  for (let i = groupA.length - 1; i > -1; --i) {
    for (let k = groupB.length - 1; k > -1; --k) {
      const a = groupA[i];
      const b = groupB[k];
      if (checkCollision(a, b)) {
        a.die(b);
        b.die(a);
      }
    }
  }
}

function checkCollision(a, b) {
  // If a remote bullet (a) targets an invader, who hasn't been shot locally yet
  // And has already been shot remotely (!) BEFORE the bullet was fired
  // Let the bullet locally hit this invader if possible to make sure it doesn't hit another invader
  const remoteBulletTargetsRemoteDeadInvader =
    !a.dead &&
    a.isRemote &&
    b.isInvader &&
    !b.localDead &&
    b.remoteDead &&
    b.remoteDeadTs > a.createdAt;

  if (!remoteBulletTargetsRemoteDeadInvader && (a.dead || b.dead || b.localDead)) {
    // Dead objects can't collide
    return false;
  }
  if (
    a.position.x < b.position.x + b.size.width &&
    a.position.x + a.size.width > b.position.x &&
    a.position.y < b.position.y + b.size.height &&
    a.position.y + a.size.height > b.position.y
  ) {
    return true;
  }
  return false;
}
