import React from 'react';
import cc from 'classcat';
import * as S from './styles';
import { schemes } from './schemes';

function ControlScheme({ type }) {
  const scheme = schemes[type];

  return (
    <S.Wrapper>
      <S.KeysRow>
        <S.KeyWrapper>
          <S.Key className={cc(['disabled', scheme.top.className])}>{scheme.top.text}</S.Key>
        </S.KeyWrapper>
      </S.KeysRow>
      <S.KeysRow className="with-bottom-labels">
        <S.KeyWrapper>
          <S.Key className={scheme.left.className}>{scheme.left.text}</S.Key>
          <S.KeyLabel className="at-bottom align-right">Move left</S.KeyLabel>
        </S.KeyWrapper>
        <S.KeyWrapper>
          <S.Key className={cc(['disabled', scheme.down.className])}>{scheme.down.text}</S.Key>
        </S.KeyWrapper>
        <S.KeyWrapper>
          <S.Key className={scheme.right.className}>{scheme.right.text}</S.Key>
          <S.KeyLabel className="at-bottom align-left">Move right</S.KeyLabel>
        </S.KeyWrapper>
      </S.KeysRow>
      <S.KeysRow className="with-top-labels">
        <S.KeyWrapper className="wide">
          <S.KeyLabel className="at-top align-center">Fire</S.KeyLabel>
          <S.Key className={cc(['wide', scheme.fire.className])}>{scheme.fire.text}</S.Key>
        </S.KeyWrapper>
      </S.KeysRow>
    </S.Wrapper>
  );
}

export default ControlScheme;
