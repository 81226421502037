import styled from 'styled-components/macro';
import { uiSizing } from '../../styles/theme';

export const Logo = styled('div')`
  max-width: 100px;
  margin: 1em auto 0;
  cursor: pointer;
`;

export const GameHeader = styled('header')`
  width: 100%;
  height: ${uiSizing.gameHeaderHeight}px;
  display: flex;
  flex-direction: column;
`;
