import React, { useState } from 'react';
import cc from 'classcat';
import { useSpring, animated } from 'react-spring';
import * as S from './styles';
import { ReactComponent as BottleIcon } from '../../assets/icons/victory/Bottle.svg';
import { ReactComponent as ShotIcon } from '../../assets/icons/victory/Shot.svg';
import { ReactComponent as FlameIcon } from '../../assets/sprites/flame.svg';
import { ReactComponent as SparkleIcon } from '../../assets/icons/victory/Sparkle.svg';
import { ReactComponent as VictoryIcon } from '../../assets/icons/victory/Victory.svg';

function VictoryAnimation({ immediate }) {
  const [landed, setLanded] = useState(false);

  const onRest = () => {
    setLanded(true);
  };

  // const landingRef = useRef();
  const landingProps = useSpring({
    from: { transform: `translate3d(0, -80vh, 0)`, x: 1 },
    to: { transform: 'translate3d(0, 0, 0)', x: 0 },
    config: {
      mass: 10,
      tension: 30,
      friction: 30,
      clamp: true,
      easing: (t) => t * (2 - t) // easeOutQuad
    },
    immediate,
    onRest: onRest
  });

  return (
    <S.VictoryWrapper>
      <animated.div
        className="bottle-shot-wrapper"
        style={{
          transform: landingProps.transform
        }}
      >
        <div className={cc(['fade-group', { visible: landed, immediate }])}>
          <SparkleIcon className="sparkle-icon top-left" />
          <SparkleIcon className="sparkle-icon small center-right" />
          <VictoryIcon className="victory-icon" />
        </div>
        <BottleIcon className="bottle-icon" />
        <ShotIcon className="shot-icon" />
        {!landed && (
          <animated.div
            style={{
              transform: landingProps.x.interpolate((x) =>
                x > 0.15 ? 'scale(1)' : `scale(${x * 8})`
              )
            }}
          >
            <FlameIcon className="flame-icon" />
          </animated.div>
        )}
      </animated.div>
    </S.VictoryWrapper>
  );
}

export default VictoryAnimation;
