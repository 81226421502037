import Invader from './Invader';
import { invaderProps, invaderPositions } from './invaderConfig';
import Bullet from '../GameObjects/Bullet';
import config from '../../config';

const INVADER_GUTTER = 0;
const MAX_INVADERS_PER_ROW = 8;
const INVADER_SPEED = config.isDev ? 0 : 3;
const INVADER_DOWN_SPEED = config.isDev ? 0 : 2;
const INVADER_TOP_OFFSET = 0;
const INVADER_LOCAL_DEAD_TIMEOUT = 1000;

const BULLET_FAST_SPEED = 4;

const getRowWidth = (sprites, defaultWidth) => {
  let width = 0;
  sprites.forEach((id) => {
    const { size = {} } = invaderProps[id] || {};
    width += size.width || defaultWidth;
  });
  return width;
};

class InvadersController {
  constructor(props) {
    this.sprites = props.sprites;
    this.deathSprite = props.deathSprite;
    this.bulletSprites = props.bulletSprites;
    this.onInvaderDeath = props.onInvaderDeath;
    this.screen = props.screen;
    this.size = props.size;
    this.selfInitiatedFire = props.selfInitiatedFire;
    this.fps = props.fps;
    this.invaders = [];
    this.prevDeadCount = 0;
    this.movedDownCount = 0;
    this.bullets = [];
    this.fireRate = props.fireRate || 5000000;
    this.maxPositionY = 0;
    this.levelSpeedIncrease = 0;
    this.currentLevel = 0;
  }

  get() {
    return this.invaders;
  }

  getAliveCount() {
    return this.invaders.length - this.prevDeadCount;
  }

  resetState() {
    this.bullets = [];
    this.invaders = [];
    this.prevDeadCount = 0;
    this.movedDownCount = 0;
    this.maxPositionY = 0;
  }

  onBulletFired = ({ fast, ...restProps }) => {
    let bulletProps = {
      spriteName: 'normal',
      getSprite: this.getBulletSprite,
      ...restProps,
    };

    if (fast) {
      bulletProps.speed = BULLET_FAST_SPEED;
      bulletProps.spriteName = 'fast';
    }

    this.bullets.push(new Bullet(bulletProps));
  };

  updateMovedDownCount(movedDownCount) {
    // Update the count only if it has increased by at least 2
    // (to ensure double moves dont happen due to latency)
    if (!movedDownCount || movedDownCount - this.movedDownCount < 2) return;
    const offset = (movedDownCount - this.movedDownCount) * INVADER_DOWN_SPEED;
    // console.log(`Updating movedDownCount by ${diff} missed down movements`);

    this.invaders.forEach((invader) => {
      invader.moveDown(offset);
    });

    this.movedDownCount = movedDownCount;
  }

  getSprite = (spriteName) => {
    return this.sprites[spriteName];
  };

  getBulletSprite = (spriteName) => {
    return this.bulletSprites[spriteName];
  };

  createLevel(levelNumber = 0) {
    this.currentLevel = levelNumber;
    this.levelSpeedIncrease = INVADER_SPEED > 0 ? levelNumber * 0.25 : 0;

    const positions = invaderPositions[levelNumber];

    const leftOffset =
      (this.screen.width -
        this.size.width * MAX_INVADERS_PER_ROW +
        INVADER_GUTTER * (MAX_INVADERS_PER_ROW - 1)) /
      2;
    let newPosition = { x: leftOffset, y: INVADER_TOP_OFFSET };

    let invaderId = 0 + levelNumber * 1000;

    positions.forEach((row, rowId) => {
      let additionalGutter =
        row.sprites.length === MAX_INVADERS_PER_ROW
          ? 0
          : ((MAX_INVADERS_PER_ROW - row.sprites.length) * this.size.width) /
            (row.sprites.length - 1);

      if (row.centered) {
        const rowWidth = getRowWidth(row.sprites, this.size.width);
        newPosition.x = (this.screen.width - rowWidth) / 2;
      }

      for (let index = 0; index < row.sprites.length; index++) {
        const invaderName = row.sprites[index];
        const {
          size = this.size,
          bulletSpriteName,
          spriteName = invaderName,
          ...overrideInvaderProps
        } = invaderProps[invaderName] || {};

        if (spriteName !== 0) {
          let invaderConfig = {
            position: {
              x: newPosition.x,
              y: newPosition.y,
            },
            onDie: this.onInvaderDeath,
            speed: INVADER_SPEED + this.levelSpeedIncrease,
            spriteName,
            getSprite: this.getSprite,
            deathSprite: this.deathSprite,
            initialFireRate: this.fireRate,
            size,
            id: invaderId,
            rowId,
            selfInitiatedFire: this.selfInitiatedFire,
            onBulletFired: this.onBulletFired,
            downSpeedDelta: 1,
            name: invaderName,
            bulletSpriteName,
            ...row.invaderProps,
            ...overrideInvaderProps,
          };

          const invader = new Invader(invaderConfig);
          invaderId++;

          this.invaders.push(invader);
        }

        if (index === row.sprites.length - 1 || row.noGutterX) {
          additionalGutter = 0;
        }

        newPosition.x += size.width + INVADER_GUTTER + additionalGutter;

        if (newPosition.x >= this.screen.width || index === row.sprites.length - 1) {
          newPosition.x = leftOffset;
          newPosition.y += size.height + INVADER_GUTTER;
        }
      }

      if (row.gutterY) {
        newPosition.y += row.gutterY;
      }
    });
  }

  renderBullets(state, dt) {
    this.bullets = this.bullets.filter((bullet) => {
      if (bullet.dead) {
        return false;
      }

      bullet.update();
      bullet.render(state);

      return true;
    });
  }

  update(dt) {
    let reverse = false;
    let deadCount = 0;
    const timestamp = Date.now();

    this.invaders.forEach((invader) => {
      if (invader.isConsideredDead()) {
        // If an invader was shot locally (using a remote bullet), but an ack from another player
        // wasn't received during the timeout, consider the invader "alive" again
        if (
          !invader.dead &&
          invader.localDead &&
          timestamp - invader.localDeadTs > INVADER_LOCAL_DEAD_TIMEOUT
        ) {
          invader.resetLocalDead();
        } else {
          // Only "really dead" invaders are counted to increase the speed of the rest
          if (invader.dead) {
            deadCount++;
          }
          // Invaders considered dead won't be rendered unless they are still dying (animating into death state)
          if (!invader.isDying()) {
            return;
          }
        }
      }

      if (
        invader.name === 'boss' &&
        invader.isInvinciblePermanently &&
        this.prevDeadCount === this.invaders.length - 1
      ) {
        invader.isInvinciblePermanently = false;
        invader.isInvincible = false;
      }

      invader.update(dt);

      const invaderPositionY = invader.position.y + invader.size.height;
      if (invaderPositionY > this.maxPositionY) {
        this.maxPositionY = invaderPositionY;
      }

      if (!reverse && invader.hasReachedBounds(this.screen.width)) {
        reverse = true;
      }
    });

    // Invaders speed up each time a fellow invader dies
    if (this.prevDeadCount !== deadCount) {
      this.prevDeadCount = deadCount;
      // Note the "+" sign, it converts the string (due to usage of toFixed() back to a number)
      let speedIncrease = +((deadCount * 2) / this.invaders.length + 1).toFixed(2);

      this.invaders.forEach((invader) => {
        invader.setSpeed((INVADER_SPEED + this.levelSpeedIncrease) * speedIncrease);
      });
    }

    if (reverse) {
      this.reverse();
    }
  }

  // Fire pre-asssigned bullets (issued by the server)
  fireBullets(bullets) {
    if (!bullets) return;

    this.invaders.forEach((invader) => {
      const bulletProps = bullets[invader.id];
      if (invader.isConsideredDead() || !bulletProps) {
        return;
      }
      invader.fireBullet(bulletProps);
    });
  }

  reverse() {
    for (let index = 0; index < this.invaders.length; index++) {
      this.invaders[index].reverse();

      // Invaders move down each time they are reversed
      this.invaders[index].moveDown(INVADER_DOWN_SPEED);
    }

    this.movedDownCount++;
  }

  serialize(shouldIncludePositions) {
    let invadersState = {};

    this.invaders.forEach((invader) => {
      let state = {
        id: invader.id,
        dead: invader.dead,
        killedBy: invader.killedBy,
        fireRateMult: invader.fireRateMult,
        livesCount: invader.livesCount,
      };
      if (invader.scoreValue) {
        state.scoreValue = invader.scoreValue;
      }
      if (shouldIncludePositions) {
        state.position = {
          x: invader.position.x,
        };
        state.direction = invader.direction;
      }
      invadersState[invader.id] = state;
    });

    return invadersState;
  }

  updateInvadersState(data, origin) {
    const isServerOrigin = origin === 'server';
    let positionsUpdated = undefined;
    const now = Date.now();

    this.invaders.forEach((i) => {
      const invader = data.list[i.id];
      if (!invader) return;

      if (invader.dead && !i.dead) {
        i.dead = true;
        i.killedBy = invader.killedBy;
        // Only if updated directly from another player (not the server states):
        // If an invader was shot by another player, save the timestamp of remote update
        // This will be used during collision detection to make sure a remote bullet can't hit
        // this invader if it was fired after than the invader died
        if (!isServerOrigin) {
          i.remoteDead = true;
          i.remoteDeadTs = now;
        }
      }
      if (i.livesCount > invader.livesCount) {
        i.livesCount = invader.livesCount;
      }
      if (invader.position) {
        if (positionsUpdated === undefined) {
          positionsUpdated = Math.abs(invader.position.x - i.position.x) > i.speed;
          // console.log(positionsUpdated, invader.position.x, i.position.x);
        }
        if (positionsUpdated) {
          i.position.x = invader.position.x;
          i.direction = invader.direction;
        }
      }
    });
  }

  destroy() {
    this.invaders = [];
  }

  render(state, dt) {
    if (state.screen.width !== this.screen.width || state.screen.height !== this.screen.height) {
      this.screen = state.screen;
    }

    this.invaders.forEach((invader) => {
      if (!invader.isConsideredDead() || invader.isDying()) {
        invader.render(state);
      }
    });

    this.renderBullets(state, dt);
  }
}

export default InvadersController;
