import styled from 'styled-components/macro';
import { colors } from '../../styles/theme';
import { BaseButton } from '../../styles/shared-styles';

export const ControlsWrapper = styled('div')`
  background-color: ${colors.red};
  display: flex;
  flex-direction: row;
  padding: 0 1.5rem 0 1rem;
  height: 100px;
  align-items: center;
  position: relative;
  margin: auto 0;

  .button-left {
    margin-right: 1.5rem;
  }

  .button-right {
    margin-right: auto;
  }

  .button-fire {
    margin-left: auto;
  }

  &.horizontal {
    padding: 0 4rem;

    .button-left {
      margin-right: 2.5rem;
    }
  }

  &.vertical {
    height: auto;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 4rem;
    padding: 0;

    .button-right, .button-left, .button-fire {
      margin: auto;
    }
    .button-fire {
      margin-top: 2.5rem;
    }
  }
`;

export const ControlButton = styled(BaseButton)`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  svg {
    vertical-align: middle;
  }

  &.active svg {
    filter: brightness(0.8);
  }
`;
