export const schemes = {
  left: {
    top: {
      text: 'W'
    },
    left: {
      text: 'A'
    },
    down: {
      text: 'S'
    },
    right: {
      text: 'D'
    },
    fire: {
      text: 'Shift'
    }
  },
  right: {
    top: {
      className: 'arrow arrow-top'
    },
    left: {
      className: 'arrow arrow-left'
    },
    down: {
      className: 'arrow arrow-down'
    },
    right: {
      className: 'arrow arrow-right'
    },
    fire: {
      text: 'Space'
    }
  }
};