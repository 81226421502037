const KEYS = {
  LEFT: 37,
  RIGHT: 39,
  // UP: 38,
  // DOWN: 40,
  A: 65,
  D: 68,
  // W: 87,
  // S: 83,
  SPACE: 32,
  ENTER: 13,
  SHIFT: 16
};

export default class InputManager {
  constructor() {
    this.pressedKeys = {};
    this.isActive = false;

    Object.keys(KEYS).forEach((key) => {
      this.pressedKeys[key.toLowerCase()] = 0;
    });
  }

  enable() {
    this.isActive = true;
    // Reset pressed keys automatically
    this.pressedKeys = {};
  }

  disable() {
    this.isActive = false;
  }

  handleKeys(value, e) {
    if (!this.isActive) {
      return;
    }

    for (let key of Object.keys(KEYS)) {
      if (KEYS[key] === e.keyCode) {
        const isShiftKey = key === 'SHIFT';
        if (!isShiftKey || (isShiftKey && e.location === 1)) {
          e.preventDefault();
          
          this.pressedKeys[key.toLowerCase()] = value;
        }
        break;
      }
    }
  }

  bindKeys() {
    window.addEventListener('keyup', this.handleKeys.bind(this, false));
    window.addEventListener('keydown', this.handleKeys.bind(this, true));
  }

  unbindKeys() {
    window.removeEventListener('keyup', this.handleKeys);
    window.removeEventListener('keydown', this.handleKeys);
  }
}
