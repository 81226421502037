import React, { Fragment } from 'react';
import VictoryAnimation from '../VictoryAnimation/VictoryAnimation';
import GameOverLogo from '../../assets/images/gameover-logo.png';
import { padNumber } from '../../utils/pad';
import * as S from './styles';

function GameOverScreen({
  style,
  score,
  onAgainClick,
  onContinueClick,
  onSaveScoreClick,
  livesCount,
  shipsCount,
  selfPlayerId,
  highlightSelf,
  scoreSaved,
  animateVictory,
  displayAdditionalOptions,
}) {
  const isVictory = livesCount[1] > 0 || livesCount[2] > 0;

  const renderScore = (playerScore, playerId) => {
    const playerName = playerId !== undefined ? `P${playerId} ` : '';
    return (
      <S.TextBlock
        className={selfPlayerId === playerId && highlightSelf ? 'is-highlighted' : undefined}
      >
        {playerName}SCORE: <span className="color-red">{padNumber(playerScore, 7)}</span>
      </S.TextBlock>
    );
  };

  return (
    <S.Wrapper style={style}>
      {isVictory ? (
        <VictoryAnimation immediate={!animateVictory} />
      ) : (
        <img className="gameover-logo" src={GameOverLogo} alt="Game Over" />
      )}
      <S.TextBlock>{isVictory ? 'INVADERS ARE DEFEATED' : 'DEFEAT'}!</S.TextBlock>
      {shipsCount > 1 ? (
        <Fragment>
          {renderScore(score[1], 1)}
          {renderScore(score[2], 2)}
        </Fragment>
      ) : (
        renderScore(score[1])
      )}
      {/* {!scoreSaved && displayAdditionalOptions && (
        <S.Button onClick={onSaveScoreClick}>SAVE SCORE</S.Button>
      )} */}
      {displayAdditionalOptions && <S.Button onClick={onAgainClick}>PLAY AGAIN</S.Button>}
      <S.Button onClick={onContinueClick}>MAIN MENU</S.Button>
    </S.Wrapper>
  );
}

export default GameOverScreen;
